import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import Select from "react-select";
import Swal from "sweetalert2";
import SearchBox from "../../common/SearchBox";
import "../../css/common.css";
import { errorToast } from "../../react-toastfiy/toast";
import SubscriptionServices from "../../services/subscription/subscription.service";
import { ActiveSubUserHeader } from "./SubscriptionHeader";
import CustomTable from "./CustomTable";

const SubscriptionExpiredUsersSubscriptionList = () => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);

  const ActiveSubUserHeader = [
    {
      header: "Sr. No",
      dataKey: "no",
    },

    {
      header: "User Name",
      dataKey: "user_name",
    },
    {
      header: "User Phone",
      dataKey: "user_phone",
    },

    {
      header: "Org Name",
      dataKey: "org_name",
    },
    {
      header: "Plan Name",
      dataKey: "plan_name",
    },
    {
      header: "Platform Type",
      dataKey: "platform_type",
    },
    {
      header: "End Date",
      dataKey: "end_date",
    },
    {
      header: "Plan Type",
      dataKey: "plan_type",
    },
  ];

  const updated_Data = {
    user_name: "",
    plan_term_available: "",
    org_name: "",
    user_phone: "",
    plan_id: "",
    end_date: "",
    auto_renew: false,
    add_ons: [
      {
        plan_id: "",
        plan_name: "",
        plan_type: "",
        plan_term: {},
        add_on_state_permission: {
          multistate: {
            selected: false,
            total_add_on: "",
            states: [""],
          },
          user_state: true,
          all_state_access: false,
        },
      },
    ],
    plan_name: "",
    final_amount: "",
    plan_term: {},
    plan_type: "",
  };

  const [activeUsers, setActiveUsers] = useState([]);
  const [filterUsersData, setFilterUsersData] = useState([]);
  const [updateData, setUpdatedData] = useState(updated_Data);
  const [Loading, setLoading] = useState(false);
  const [updatedFlag, setUpdatedFlag] = useState(false);
  const [allNewPlan, setAllNewPlan] = useState([]);
  const [allAddOnPlan, setAllAddOnPlan] = useState([]);
  const [allStatePermission, setAllStatePermission] = useState([]);
  const [accordionOpenStates, setAccordionOpenStates] = useState(
    Array(filterUsersData?.length).fill(false)
  );
  let plan_id = updateData.plan_id;

  console.log({ filterUsersData });

  useEffect(() => {}, [filterUsersData]);
  useEffect(() => {
    try {
      setLoading(true);
      let form_data = new FormData();
      form_data.append("data_type", "expired");
      SubscriptionServices.GetAllRapibidUsers(form_data).then((res) => {
        if (res?.status === 200) {
          const user_data = res?.data?.map((data, index) => ({
            ...data,
            no: index + 1,
          }));
          setActiveUsers(user_data);
          setFilterUsersData(user_data);
          SubscriptionServices.GetAllNewSubscriptionPlan().then((res) => {
            if (res?.status === 200) {
              setLoading(false);
              let newPlan = res.data.map((val) => {
                return {
                  label: `${val.name}  ${val.id}`,
                  value: val,
                };
              });
              setAllNewPlan(newPlan);
            }
          });
        } else {
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    if (plan_id !== "") {
      let form_data = new FormData();
      form_data.append("plan_id", plan_id);
      SubscriptionServices.GetAllAddonSubscriptionPlan(form_data).then(
        (res) => {
          if (res?.status === 200) {
            let all_AddOn_Plan = res.data.map((val) => {
              return {
                label: `${val.name}  ${val.id}`,
                value: val,
              };
            });

            setAllAddOnPlan(all_AddOn_Plan);
          }
        }
      );
      SubscriptionServices.GetAllStatePermissionsSubscriptionPlan().then(
        (res) => {
          if (res?.status === 200) {
            let all_state_permission = res.data.map((val) => {
              return {
                label: `${val.name}  ${val.id}`,
                value: val,
              };
            });
            setAllStatePermission(all_state_permission);
          }
        }
      );
    }
  }, [plan_id && accordionOpenStates]);

  const handleChangeNewPlan = (e, index, value) => {
    const newPlanId = e.value.id;
    setActiveUsers((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        plan_term_selected: {},
        plan_id: newPlanId,
        plan_name: e.value.name,
        plan_term_available: e?.value?.plan_term,
        add_ons: [],
        state_permissions: [],
      };
      return newState;
    });
    setFilterUsersData((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        plan_term_selected: {},
        plan_id: newPlanId,
        plan_name: e.value.name,
        plan_term_available: e?.value?.plan_term,
        add_ons: [],
        state_permissions: [],
      };
      return newState;
    });
    setUpdatedFlag(false);

    setUpdatedData((prevState) => {
      const newState = { ...prevState };
      newState.plan_name = e.value.name;
      newState.plan_term_available = e.value.plan_term;
      newState.plan_id = newPlanId;
      newState.plan_term = {};
      newState.plan_type = value?.plan_type;
      newState.final_amount = "";
      newState.user_name = value.user_name;
      newState.user_phone = value.user_phone;
      newState.org_name = value.org_name;
      newState.end_date = value.end_date;
      newState.add_ons = [];
      return newState;
    });

    let form_data = new FormData();
    form_data.append("plan_id", e.value.id);
    SubscriptionServices.GetAllAddonSubscriptionPlan(form_data).then((res) => {
      if (res?.status === 200) {
        let all_AddOn_Plan = res?.data.map((val) => {
          return {
            label: `${val.name}  ${val.id}`,
            value: val,
          };
        });
        setAllAddOnPlan(all_AddOn_Plan);
      }
    });
  };
  const handleChangeAddonPlan = (e, index, value) => {
    const newPlanId = e.value.id;
    const plan_term = Object.keys(value.plan_term_selected).map(
      (key, index) => {
        const selectedValue = key;
        return selectedValue;
      }
    );

    let state_list = value.state_permissions;

    const state_permission_values = state_list.map((stateId) => {
      return allStatePermission.find((option) => option?.value?.id === stateId);
    });

    const setPlan_term = { [plan_term[0]]: e.value.plan_term[plan_term[0]] };

    let slugResults = [];

    state_list?.forEach((statePermission) => {
      const foundValue = state_permission_values?.find(
        (item) => item.value.id === statePermission
      );
      const slug = {
        plan_id: foundValue?.value?.id || "",
        plan_name: foundValue?.value?.name || "",

        plan_term: {
          [plan_term[0]]:
            slugResults.length <
            e?.value?.add_on_state_permission.multistate.total_add_on
              ? 0
              : foundValue?.value?.plan_term[plan_term[0]],
        },
        plan_type: foundValue?.value?.plan_type,
      };

      slugResults.push(slug);
    });

    setActiveUsers((prevState) => {
      return prevState.map((user, i) => {
        if (i === index) {
          return {
            ...user,
            add_ons: [newPlanId],
          };
        }
        return user;
      });
    });
    setFilterUsersData((prevState) => {
      return prevState.map((user, i) => {
        if (i === index) {
          return {
            ...user,
            add_ons: [newPlanId],
          };
        }
        return user;
      });
    });
    setUpdatedFlag(true);
    setUpdatedData((prevState) => {
      const newState = { ...prevState };
      newState.plan_name = e.value.name;
      newState.plan_term_available = value.plan_term_available;
      newState.plan_term = value.plan_term_selected;
      newState.plan_id = value.plan_id;
      newState.plan_type = value?.plan_type;
      newState.user_name = value.user_name;
      newState.user_phone = value.user_phone;
      newState.org_name = value.org_name;
      newState.end_date = value.end_date;
      newState.add_ons = [
        {
          plan_id: e.value.id,
          plan_name: e.value.name,
          plan_type: e.value.plan_type,
          plan_term: setPlan_term,
          add_on_state_permission: e.value.add_on_state_permission,
        },

        ...slugResults,
      ];
      return newState;
    });
  };

  const handleChangeStatePermissionPlan = (e, index, value) => {
    const newPlanIdArray = e.map((item) => item.value.id);
    let state_list = e.map((val) => val?.value?.id);
    const plan_term = Object.keys(value.plan_term_selected).map(
      (key, index) => {
        const selectedValue = key;

        return selectedValue;
      }
    );
    const foundValues = allStatePermission.filter((item) =>
      value?.state_permissions?.includes(item.value.id)
    );

    const state_permission_list = e.map((val) => val.value);
    const add_ons_value = allAddOnPlan.find((option) =>
      value.add_ons.includes(option?.value?.id)
    );

    let state = [];
    const slugResults = [];
    if (
      add_ons_value &&
      add_ons_value.value.add_on_state_permission.multistate.total_add_on
    ) {
      state_list?.forEach((statePermission) => {
        const foundValue = state_permission_list?.find(
          (item) => item?.id === statePermission
        );
        const slug = {
          plan_id: foundValue?.id || "",
          plan_name: foundValue?.name || "",

          plan_term: {
            [plan_term[0]]:
              slugResults.length <
              add_ons_value.value.add_on_state_permission.multistate
                .total_add_on
                ? 0
                : foundValue?.plan_term[plan_term[0]],
          },
          plan_type: foundValue?.plan_type,
        };

        slugResults.push(slug);
      });

      const setPlan_term = {
        [plan_term[0]]: add_ons_value?.value?.plan_term[plan_term[0]],
      };
      setActiveUsers((prevState) => {
        return prevState.map((user, i) => {
          if (i === index) {
            const existingPlanIds = user.state_permissions.map((item) => item);

            const newPlanIdsToAdd = newPlanIdArray.filter(
              (newPlanId) => !existingPlanIds.includes(newPlanId)
            );

            return {
              ...user,
              state_permissions: [
                ...user.state_permissions,
                ...newPlanIdsToAdd,
              ],
            };
          }
          return user;
        });
      });
      setFilterUsersData((prevState) => {
        return prevState.map((user, i) => {
          if (i === index) {
            const existingPlanIds = user.state_permissions.map((item) => item);

            const newPlanIdsToAdd = newPlanIdArray.filter(
              (newPlanId) => !existingPlanIds.includes(newPlanId)
            );

            return {
              ...user,
              state_permissions: [
                ...user.state_permissions,
                ...newPlanIdsToAdd,
              ],
            };
          }
          return user;
        });
      });
      setUpdatedFlag(true);
      setUpdatedData((prevState) => {
        const newState = { ...prevState };
        newState.plan_name = value.plan_name;
        newState.plan_term_available = value.plan_term_available;
        newState.plan_term = value.plan_term_selected;
        newState.plan_id = value.plan_id;
        newState.plan_type = value?.plan_type;
        newState.user_name = value.user_name;
        newState.user_phone = value.user_phone;
        newState.org_name = value.org_name;
        newState.end_date = value.end_date;
        newState.add_ons = [
          {
            plan_id: add_ons_value?.value?.id,
            plan_name: add_ons_value?.value?.name,
            plan_type: add_ons_value?.value?.plan_type,
            plan_term: setPlan_term,
            states: state,
            add_on_state_permission:
              add_ons_value?.value?.add_on_state_permission,
          },
          ...slugResults,
        ];

        return newState;
      });
    } else {
      state_list?.forEach((statePermission) => {
        const foundValue = state_permission_list?.find(
          (item) => item?.id === statePermission
        );
        const slug = {
          plan_id: foundValue?.id || "",
          plan_name: foundValue?.name || "",

          plan_term: {
            [plan_term[0]]: foundValue?.plan_term[plan_term[0]],
          },
          plan_type: foundValue?.plan_type,
        };

        const state_id = [foundValue?.plan_state_id];

        slugResults.push(slug);
        state.push(...state_id);
      });

      setActiveUsers((prevState) => {
        return prevState.map((user, i) => {
          if (i === index) {
            const existingPlanIds = user.state_permissions.map((item) => item);

            const newPlanIdsToAdd = newPlanIdArray.filter(
              (newPlanId) => !existingPlanIds.includes(newPlanId)
            );

            return {
              ...user,
              state_permissions: [
                ...user.state_permissions,
                ...newPlanIdsToAdd,
              ],
            };
          }
          return user;
        });
      });
      setFilterUsersData((prevState) => {
        return prevState.map((user, i) => {
          if (i === index) {
            const existingPlanIds = user.state_permissions.map((item) => item);

            const newPlanIdsToAdd = newPlanIdArray.filter(
              (newPlanId) => !existingPlanIds.includes(newPlanId)
            );

            return {
              ...user,
              state_permissions: [
                ...user.state_permissions,
                ...newPlanIdsToAdd,
              ],
            };
          }
          return user;
        });
      });
      setUpdatedFlag(true);
      setUpdatedData((prevState) => {
        const newState = { ...prevState };
        newState.plan_name = value.plan_name;
        newState.plan_term_available = value.plan_term_available;
        newState.plan_term = value.plan_term_selected;
        newState.plan_id = value.plan_id;
        newState.plan_type = value?.plan_type;
        newState.user_name = value.user_name;
        newState.user_phone = value.user_phone;
        newState.org_name = value.org_name;
        newState.end_date = value.end_date;
        newState.add_ons = [...slugResults];

        return newState;
      });
    }
  };
  const handleAccordionButtonClick = (e, index, value) => {
    let resultArray;
    if (Array.isArray(filterUsersData)) {
      resultArray = Array(filterUsersData.length).fill(false);
    } else if (typeof filterUsersData === "object") {
      resultArray = Array(Object.keys(filterUsersData).length).fill(false);
    }
    if (index >= 0 && index < resultArray.length) {
      resultArray = resultArray.map((indexValue, i) =>
        index === i ? !indexValue : false
      );

      setAccordionOpenStates(resultArray);
      if (accordionOpenStates[index] === true) {
        setAccordionOpenStates((prevState) => {
          const updatedStates = [...prevState];
          updatedStates[index] = !updatedStates[index];
          return updatedStates;
        });
      }
    } else {
      setAccordionOpenStates((prevState) => {
        const updatedStates = [...prevState];
        updatedStates[index] = !updatedStates[index];
        return updatedStates;
      });
    }
    setUpdatedFlag(false);
    setUpdatedData((prevState) => ({
      ...prevState,
      plan_term: value.plan_term_selected,
      plan_name: value?.plan_name,
      plan_term_available: value?.plan_term_available,
      plan_id: value?.plan_id,
      plan_type: value?.plan_type,
      user_name: value?.user_name,
      user_phone: value?.user_phone,
      org_name: value?.org_name,
      end_date: value?.end_date,
      add_ons: [],
    }));
  };

  const handleSave = (e, index) => {
    let order_id = filterUsersData[index].order_id;
    let form_data = new FormData();
    form_data.append("assigned_by", user.phone);
    form_data.append("end_date", updateData.end_date);
    form_data.append("final_amount", updateData.final_amount);
    form_data.append("auto_renew", false);
    form_data.append("org_name", updateData.org_name);
    form_data.append("plan_id", updateData.plan_id);
    form_data.append("plan_name", updateData.plan_name);
    form_data.append("plan_term", JSON.stringify(updateData.plan_term));
    form_data.append("order_id", order_id);
    form_data.append(
      "plan_term_available",
      JSON.stringify(updateData.plan_term_available)
    );
    form_data.append("plan_type", updateData.plan_type);
    form_data.append("user_name", updateData.user_name);
    form_data.append("user_phone", updateData.user_phone);
    form_data.append("add_ons", JSON.stringify(updateData.add_ons));

    Swal.fire({
      title: "Are you sure?",
      html: "You want to Save this subscription plan",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes,Save it!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await SubscriptionServices.UpdateUserList(form_data);
          if (response.status === 200) {
            Swal.fire("Save", `Your data has been Save`, "success").then(
              (result) => {
                result &&
                  window.location.replace(
                    "/subscription/manage-users-analysis"
                  );
              }
            );
          }
          response.status !== 200 && errorToast(response.msg);
        } catch (error) {
          console.log(error);
          errorToast(error.message);
        }
      },
    });
  };
  const handleAccordionBodyClick = (e) => {
    e.stopPropagation();
  };

  const handleCheckedRadio = (e, index, value) => {
    const { checked } = e?.target;

    const planTerm = e.target.getAttribute("plan_term");

    const add_ons_value = allAddOnPlan.find((option) =>
      value?.add_ons.includes(option?.value?.id)
    );

    const setPlan_term = {
      [planTerm]: add_ons_value?.value?.plan_term[planTerm],
    };

    const foundValues = allStatePermission.filter((item) =>
      value?.state_permissions?.includes(item.value.id)
    );

    const slugResults = [];
    let man_total = parseInt(e.target.value);

    if (value?.state_permissions !== 0 && add_ons_value && foundValues) {
      value?.state_permissions?.forEach((statePermission) => {
        const foundValue = foundValues?.find(
          (item) => item?.value?.id === statePermission
        );

        const slug = {
          plan_id: foundValue?.value?.id || "",
          plan_name: foundValue?.value.name || "",
          plan_term: {
            [planTerm]:
              slugResults.length <
              add_ons_value.value.add_on_state_permission.multistate
                .total_add_on
                ? 0
                : foundValue.value.plan_term[planTerm],
          },
          plan_type: foundValue.value.plan_type,
        };

        slugResults.push(slug);
      });
      setUpdatedFlag(true);
      setUpdatedData((prevState) => ({
        ...prevState,
        plan_term: {
          [planTerm]: e?.target?.value,
        },
        plan_name: value?.plan_name,
        final_amount: "",
        plan_term_available: value?.plan_term_available,
        plan_id: value?.plan_id,
        plan_type: value?.plan_type,
        user_name: value?.user_name,
        user_phone: value?.user_phone,
        org_name: value?.org_name,
        end_date: value?.end_date,
        add_ons: [
          {
            plan_id: add_ons_value?.value?.id,
            plan_name: add_ons_value?.value?.name,
            plan_type: add_ons_value?.value?.plan_type,
            plan_term: setPlan_term,

            add_on_state_permission:
              add_ons_value?.value?.add_on_state_permission,
          },
          ...slugResults,
        ],
      }));

      setActiveUsers((prevState) => {
        const updatedDataCopy = [...prevState];
        updatedDataCopy[index].plan_term_selected = {
          [planTerm]: e?.target?.value,
        };
        return updatedDataCopy;
      });
      setFilterUsersData((prevState) => {
        const updatedDataCopy = [...prevState];
        updatedDataCopy[index].plan_term_selected = {
          [planTerm]: e?.target?.value,
        };
        return updatedDataCopy;
      });
    } else {
      value?.state_permissions?.forEach((statePermission) => {
        const foundValue = foundValues?.find(
          (item) => item?.value?.id === statePermission
        );
        const slug = {
          plan_id: foundValue?.value?.id || "",
          plan_name: foundValue?.value.name || "",
          plan_term: {
            [planTerm]: foundValue.value.plan_term[planTerm],
          },
          plan_type: foundValue.value.plan_type,
        };

        slugResults.push(slug);
      });

      setUpdatedFlag(true);
      setUpdatedData((prevState) => ({
        ...prevState,
        plan_term: {
          [planTerm]: e?.target?.value,
        },
        plan_name: value?.plan_name,
        final_amount: man_total,
        plan_term_available: value?.plan_term_available,
        plan_id: value?.plan_id,
        plan_type: value?.plan_type,
        user_name: value?.user_name,
        user_phone: value?.user_phone,
        org_name: value?.org_name,
        end_date: value?.end_date,
        add_ons: [...slugResults],
      }));
      setActiveUsers((prevState) => {
        const updatedDataCopy = [...prevState];
        updatedDataCopy[index].plan_term_selected = {
          [planTerm]: e?.target?.value,
        };
        updatedDataCopy[index].add_ons = [];
        return updatedDataCopy;
      });
      setFilterUsersData((prevState) => {
        const updatedDataCopy = [...prevState];
        updatedDataCopy[index].plan_term_selected = {
          [planTerm]: e?.target?.value,
        };
        updatedDataCopy[index].add_ons = [];
        return updatedDataCopy;
      });
    }
  };

  const handleRemoveAddOnItem = (item, index) => {
    let planTerm = filterUsersData[index].plan_term_selected;
    let plan_Term = Object.keys(planTerm).map((key, index) => {
      const selectedValue = key;
      return selectedValue;
    });

    const currentActiveUser = filterUsersData[index];
    const updatedValue = currentActiveUser.add_ons.filter(
      (value) => value !== item
    );
    const slugResults = [];
    if (updatedValue == 0) {
      const foundValues = allStatePermission.filter((item) =>
        filterUsersData[index]?.state_permissions?.includes(item.value.id)
      );

      filterUsersData[index]?.state_permissions?.forEach((statePermission) => {
        const foundValue = foundValues?.find(
          (item) => item?.value?.id === statePermission
        );
        const slug = {
          plan_id: foundValue?.value?.id || "",
          plan_name: foundValue?.value.name || "",
          plan_term: {
            [plan_Term]: foundValue.value.plan_term[plan_Term],
          },
          plan_type: foundValue.value.plan_type,
        };

        slugResults.push(slug);
      });
    }

    setActiveUsers((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...prevState[index],
        add_ons: [...updatedValue],
      };
      return newState;
    });
    setFilterUsersData((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...prevState[index],
        add_ons: [...updatedValue],
      };
      return newState;
    });
    setUpdatedFlag(true);

    setUpdatedData((value) => ({
      ...value,
      add_ons: [...slugResults],
    }));
  };

  const handleRemoveStatePermission = (item, index, value) => {
    const currentActiveUser = filterUsersData[index];
    const plan_term = Object.keys(value.plan_term_selected).map(
      (key, index) => {
        const selectedValue = key;

        return selectedValue;
      }
    );

    const updatedValue = currentActiveUser.state_permissions.filter(
      (value) => value !== item
    );
    const add_ons_value = allAddOnPlan.find((option) =>
      value.add_ons.includes(option?.value?.id)
    );

    let slugResults = [];
    if (
      add_ons_value &&
      add_ons_value.value.add_on_state_permission.multistate.total_add_on
    ) {
      const setPlan_term = {
        [plan_term[0]]: add_ons_value?.value?.plan_term[plan_term[0]],
      };

      updatedValue?.forEach((statePermission) => {
        const foundValue = allStatePermission?.find(
          (item) => item.value.id === statePermission
        );
        const slug = {
          plan_id: foundValue?.value?.id || "",
          plan_name: foundValue?.value?.name || "",

          plan_term: {
            [plan_term[0]]:
              slugResults.length <
              add_ons_value.value.add_on_state_permission.multistate
                .total_add_on
                ? 0
                : foundValue?.value?.plan_term[plan_term[0]],
          },
          plan_type: foundValue?.value?.plan_type,
        };

        slugResults.push(slug);
      });

      setActiveUsers((prevState) => {
        const newState = [...prevState];
        newState[index] = {
          ...prevState[index],
          state_permissions: [...updatedValue],
        };
        return newState;
      });
      setFilterUsersData((prevState) => {
        const newState = [...prevState];
        newState[index] = {
          ...prevState[index],
          state_permissions: [...updatedValue],
        };
        return newState;
      });
      setUpdatedFlag(true);
      setUpdatedData((value) => ({
        ...value,
        add_ons: [
          {
            plan_id: add_ons_value?.value?.id,
            plan_name: add_ons_value?.value?.name,
            plan_type: add_ons_value?.value?.plan_type,
            plan_term: setPlan_term,
            // states: state,
            add_on_state_permission:
              add_ons_value?.value?.add_on_state_permission,
          },
          ...slugResults,
        ],
      }));
    } else {
      updatedValue?.forEach((statePermission) => {
        const foundValue = allStatePermission?.find(
          (item) => item.value.id === statePermission
        );
        const slug = {
          plan_id: foundValue?.value?.id || "",
          plan_name: foundValue?.value?.name || "",

          plan_term: {
            [plan_term[0]]: foundValue?.value?.plan_term[plan_term[0]],
          },
          plan_type: foundValue?.value?.plan_type,
        };

        slugResults.push(slug);
      });

      setActiveUsers((prevState) => {
        const newState = [...prevState];
        newState[index] = {
          ...prevState[index],
          state_permissions: [...updatedValue],
        };
        return newState;
      });
      setFilterUsersData((prevState) => {
        const newState = [...prevState];
        newState[index] = {
          ...prevState[index],
          state_permissions: [...updatedValue],
        };
        return newState;
      });
      setUpdatedFlag(true);
      setUpdatedData((value) => ({
        ...value,
        add_ons: [...slugResults],
      }));
    }
  };

  let Total = Object.values(updateData.plan_term).join("");
  const result = updateData.add_ons.map((val) =>
    Object.values(val.plan_term).join("")
  );
  const totalAddOns = result.reduce((acc, currentValue) => {
    return acc + parseInt(currentValue);
  }, 0);
  let grand_total = parseInt(Total) + parseInt(totalAddOns);

  useEffect(() => {
    if (grand_total) {
      setUpdatedData((prevData) => {
        return {
          ...prevData,
          final_amount: parseInt(Total) + parseInt(totalAddOns),
        };
      });
    }

    // }
  }, [grand_total]);

  const handleChangeEndDate = (e, index, value) => {
    const { name } = e.target;
    const newValue = e.target.value.replace("T", " ");

    setUpdatedData((prevState) => {
      return {
        ...prevState,
        [name]: newValue,
      };
    });

    setActiveUsers((prevState) => {
      const updatedUsers = [...prevState];
      updatedUsers[index][name] = newValue;
      return updatedUsers;
    });
    setFilterUsersData((prevState) => {
      const updatedUsers = [...prevState];
      updatedUsers[index][name] = newValue;
      return updatedUsers;
    });
  };

  try {
    return (
      <>
        {/* {Loading && Object.entries(filterUsersData).length === 0 ? (
          <div
            className="d-flex justify-content-center container"
            style={{ minHeight: "80vh", alignItems: "center" }}
          >
            <ReactLoading
              type={"bubbles"}
              color={"black"}
              height={100}
              width={100}
              className="reactLoadingDash"
            />
          </div>
        ) : (
          <>
            <div className=" container mt-3 col-12">
              <div className="row mt-3">
                <div className="col-md-6 col-lg-4 form-group">
                  <h4>Expired User Subscription List</h4>
                </div>
                <div className="ms-auto col-md-6 col-lg-4 form-group ">
                  <SearchBox
                    allData={activeUsers}
                    setFilteredData={setFilterUsersData}
                  />
                </div>
              </div>
            </div>
            {!Loading && Object.entries(filterUsersData)?.length === 0 ? (
              <>
                <div className="container table-responsive mt-2">
                  <div className="container">
                    <p className="text-center text-danger">No data</p>
                  </div>
                </div>
              </>
            ) : (
              <div className=" container mt-3 col-12">
                {Object.entries(filterUsersData)?.length !== 0 &&
                  Object.entries(filterUsersData)?.map((value, index) => (
                    <React.Fragment key={value[1].user_phone}>
                      <div class="accordion mt-3" id="accordionExample">
                        <div
                          class="accordion-item"
                          style={{ backgroundColor: "white" }}
                        >
                          <h6
                            id={`headingOne${index}`}
                            className=" accordion-header d-flex flex-wrap justify-content-between align-items-center gap-2 col-12 col-sm-12 col-md-12 col-lg-12 py-2"
                          >
                            <span className="ms-2 col-12 col-sm-12 col-md-4 col-lg-3">
                              {value[1].user_name} - {value[1].user_phone}
                            </span>

                            <span className="col-12 col-sm-12 col-md-2 col-lg-2">
                              {value[1].org_name}
                            </span>

                            <span className="col-12 col-sm-12 col-md-2 col-lg-1">
                              {value[1].plan_name}
                            </span>

                            <span className="col-5 col-sm-3 col-md-3 col-lg-2">
                              {value[1].end_date}
                            </span>

                            <button
                              className={`accordion-button ${
                                accordionOpenStates[index]
                                  ? "open"
                                  : "collapsed"
                              }`}
                              type="button"
                              style={{
                                Padding: "1px",
                                maxHeight: "25px",
                                maxWidth: "5px",
                                backgroundColor: "transparent",
                                boxShadow: "none",
                                Margin: "1px",
                                marginRight: "5px",
                              }}
                              onClick={(e) =>
                                handleAccordionButtonClick(e, index, value[1])
                              }
                              data-bs-toggle="collapse"
                              data-bs-target={`collapse${index}`}
                              aria-expanded={accordionOpenStates[index]}
                              aria-controls={`collapse${index}`}
                            ></button>
                          </h6>

                          <div
                            id={`#collapse${index}`}
                            onClick={handleAccordionBodyClick}
                            className={`accordion-collapse collapse ${
                              accordionOpenStates[index] ? "show" : ""
                            }`}
                            data-bs-parent={`#accordionExample${index}`}
                          >
                            <div class="accordion-body">
                              <hr className="soften mt-2" />
                              <div className="accordion-body">
                                <div className="row mt-3">
                                  <label className="mt-3" htmlFor="name">
                                    New Plan
                                  </label>
                                  <div className="col-md-6 col-lg-4 form-group">
                                    <Select
                                      options={allNewPlan}
                                      // isDisabled
                                      placeholder="Assign Plan"
                                      id="included_items"
                                      type="text"
                                      name="plan_id"
                                      value={allNewPlan?.find(
                                        (option) =>
                                          option.value.id === value[1].plan_id
                                      )}
                                      onChange={(e) => {
                                        handleChangeNewPlan(e, index, value[1]);
                                      }}
                                      onClick={handleAccordionBodyClick}
                                    />
                                  </div>
                                  <div className="col-md-6 col-lg-4 form-group">
                                    <input
                                      className="form-control"
                                      id="end_date"
                                      name="end_date"
                                      placeholder="End Date"
                                      type="datetime-local"
                                      value={value[1].end_date}
                                      onChange={(e) =>
                                        handleChangeEndDate(e, index, value[1])
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row mt-3">
                                  <div className="col-md-3 form-group">
                                    <div
                                      className="form-check"
                                      id={`${value[1]?.user_phone}_select`}
                                    >
                                      <label
                                        className="form-check-label"
                                        for="flexCheckDefault"
                                      >
                                        Monthly -
                                        {value[1]?.plan_term_available?.monthly}
                                      </label>
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        plan_term={"monthly"}
                                        name={value[1].user_phone + "monthly"}
                                        id={value[1].user_phone + "monthly"}
                                        value={parseInt(
                                          value[1]?.plan_term_available?.monthly
                                        )}
                                        // checked={
                                        //   value[1]?.plan_term_selected?.monthly
                                        //     ? true
                                        //     : false
                                        // }
                                        checked={
                                          JSON.stringify(
                                            Object.keys(
                                              value[1]?.plan_term_selected
                                            ).map((val) => val)
                                          ) === JSON.stringify(["monthly"])
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          handleCheckedRadio(
                                            e,
                                            index,
                                            value[1]
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-check">
                                      <label
                                        className="form-check-label"
                                        for={value.user_phone + "quarterly"}
                                      >
                                        Quarterly -
                                        {
                                          value[1]?.plan_term_available
                                            ?.quarterly
                                        }
                                      </label>
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        plan_term={"quarterly"}
                                        name={value[1].user_phone + "quarterly"}
                                        id={value[1].user_phone + "quarterly"}
                                        value={parseInt(
                                          value[1]?.plan_term_available
                                            ?.quarterly
                                        )}
                                        // checked={
                                        //   value[1]?.plan_term_selected?.quarterly
                                        //     ? true
                                        //     : false
                                        // }
                                        checked={
                                          JSON.stringify(
                                            Object.keys(
                                              value[1]?.plan_term_selected
                                            ).map((val) => val)
                                          ) === JSON.stringify(["quarterly"])
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          handleCheckedRadio(
                                            e,
                                            index,
                                            value[1]
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-check">
                                      <label
                                        className="form-check-label"
                                        for="flexCheckDefault"
                                      >
                                        Yearly -
                                        {value[1]?.plan_term_available?.yearly}
                                      </label>
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        plan_term={"yearly"}
                                        name={value[1].user_phone + "yearly"}
                                        id={value[1].user_phone + "yearly"}
                                        value={
                                          value[1]?.plan_term_available?.yearly
                                        }
                                        // checked={
                                        //   value[1]?.plan_term_selected?.yearly
                                        //     ? true
                                        //     : false
                                        // }

                                        checked={
                                          JSON.stringify(
                                            Object.keys(
                                              value[1]?.plan_term_selected
                                            ).map((val) => val)
                                          ) === JSON.stringify(["yearly"])
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          handleCheckedRadio(
                                            e,
                                            index,
                                            value[1]
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-3">
                                  <label className="mt-3" htmlFor="name">
                                    Add-on Plan
                                  </label>
                                  <div className="col-md-6 col-lg-4 form-group">
                                    <Select
                                      options={allAddOnPlan}
                                      placeholder="Assign Plan"
                                      id="add-on_plan"
                                      type="text"
                                      name="add-on_plan"
                                      value={value?.add_ons?.map(
                                        (selectedPlanId) => {
                                          return allAddOnPlan?.find(
                                            (option) =>
                                              option?.value?.id ===
                                              selectedPlanId
                                          );
                                        }
                                      )}
                                      onChange={(e) => {
                                        handleChangeAddonPlan(e, index, value);
                                      }}
                                    />
                                  </div>

                                  <div className="col-md-6  form-group">
                                    {value?.add_ons?.length !== 0 &&
                                      value?.add_ons?.map((item) => (
                                        <div
                                          key={item}
                                          className="item-capsule-accordion "
                                        >
                                          <span className=" item-text-accordion">
                                            {item}
                                          </span>
                                          <i
                                            className="bi bi-x"
                                            onClick={() =>
                                              handleRemoveAddOnItem(item, index)
                                            }
                                          ></i>
                                        </div>
                                      ))}
                                  </div>
                                </div>

                                <div className="row mt-3">
                                  <label htmlFor="name">
                                    State Permission Plan
                                  </label>
                                  <div className="col-md-6 col-lg-4 ">
                                    <Select
                                      isMulti
                                      controlShouldRenderValue={false}
                                      options={allStatePermission}
                                      placeholder="Assign Plan"
                                      id="add-on_plan"
                                      type="text"
                                      name="add-on_plan"
                                      value={value?.state_permissions?.map(
                                        (selectedPlanId) => {
                                          return allStatePermission?.find(
                                            (option) =>
                                              option?.value?.id ===
                                              selectedPlanId
                                          );
                                        }
                                      )}
                                      onChange={(e) => {
                                        handleChangeStatePermissionPlan(
                                          e,
                                          index,
                                          value
                                        );
                                      }}
                                    />
                                  </div>

                                  <div className="col-md-6 col-lg-6 form-group">
                                    {value?.state_permissions?.length !== 0 &&
                                      value?.state_permissions?.map((item) => (
                                        <div
                                          key={item}
                                          className="item-capsule-accordion "
                                        >
                                          <span className=" item-text-accordion">
                                            {item}
                                          </span>
                                          <i
                                            className="bi bi-x"
                                            onClick={() =>
                                              handleRemoveStatePermission(
                                                item,
                                                index,
                                                value
                                              )
                                            }
                                          ></i>
                                        </div>
                                      ))}
                                  </div>
                                </div>

                                {updatedFlag && (
                                  <>
                                    <div className="container table-responsive mt-2">
                                      <table className="table table-bordered table-hover">
                                        <>
                                          <thead>
                                            <tr>
                                              <th>Plan Name</th>
                                              <th>Plan Type</th>
                                              <th>Plan Term</th>
                                              <th>Plan Amount</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>{updateData.plan_name}</td>
                                              <td>{updateData.plan_type}</td>
                                              <td>
                                                <div>
                                                  {Object.keys(
                                                    updateData?.plan_term
                                                  )}
                                                </div>
                                              </td>
                                              <td>
                                                <div>
                                                  {Object.values(
                                                    updateData?.plan_term
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                            {updateData.add_ons &&
                                              updateData.add_ons.map(
                                                (val, valIndex) => (
                                                  <tr key={valIndex}>
                                                    <td>{val.plan_name}</td>
                                                    <td>{val.plan_type}</td>
                                                    <td>
                                                      <div>
                                                        {Object.keys(
                                                          val?.plan_term
                                                        )}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div>
                                                        {Object.values(
                                                          val?.plan_term
                                                        )}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                          </tbody>
                                          <tfoot>
                                            <tr>
                                              <td colSpan={3}>Grand Total</td>
                                              <td>{updateData.final_amount}</td>
                                            </tr>
                                          </tfoot>
                                        </>
                                      </table>
                                    </div>
                                  </>
                                )}
                                <div className="row mt-3">
                                  <div className="col-md-10 col-lg-8 ">
                                    <button
                                      className="btn btn-primary me-5 col-4 col-sm-5 col-md-2"
                                      onClick={(e) => handleSave(e, index)}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            )}
          </>
        )} */}

        <div className="container mt-3">
          <div className="col-md-12 col-12 col-lg-12 mb-2 ">
            <div className="d-flex flex-wrap justify-content-between align-items-end">
              <div className="col-lg-3 col-12 col-md-4 col-sm-12">
                <h4>Expired User Subscription List</h4>
              </div>
              <div className="col-lg-3 col-12 col-md-4 col-sm-12 ms-auto">
                <SearchBox
                  allData={activeUsers}
                  setFilteredData={setFilterUsersData}
                />
              </div>
            </div>
          </div>
          <CustomTable
            loading={Loading}
            columns={ActiveSubUserHeader}
            data={filterUsersData}
            // handleDelete={handleDelete}
            action={"active"}
          />
        </div>
      </>
    );
  } catch (error) {
    console.log("error", error);
  }
};

export default SubscriptionExpiredUsersSubscriptionList;
