import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";

const EditAssetImage = () => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  const history = useHistory();
  const source = axios.CancelToken.source();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 486);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 486);
  };

  useEffect(() => {
    document.title = "Asset Images";
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const singleuploadbreadcrumb = {
    height: isSmallScreen ? "60px" : "30px",
    borderRadius: "0",
  };

  const singleuploadbreadcrumbMargin = {
    marginTop: isSmallScreen ? "-14px" : "-12px",
    marginBottom: isSmallScreen ? "14px" : "",
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const asset_id = searchParams.get("ref_no");
  const uid = searchParams.get("uid");
  const url = `${asset_id}&uid=${uid}`;

  const [data, setData] = useState([]);
  const [checkedItemsEditPrimary, setCheckedItemsEditPrimary] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (asset_id !== "") {
      setLoading(true);
      let form_data = new FormData();
      form_data.append("ref_no", asset_id);
      auctionService.GetAssetImage(asset_id, form_data, source).then((res) => {
        setLoading(false);
        if (res.status === 404) {
          setLoading(false);
        } else {
          setData(res);
          setLoading(false);
        }
      });
    }
  }, []);

  const handleDelete = (e, index, value) => {
    e.preventDefault();

    const RemovedData = data[value];
    const form_data = new FormData();
    form_data.append("toDelete", index.id);
    Swal.fire({
      title: "Are you sure?",
      html: `<p>You want to delete ${index.id} picture!</p>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await auctionService.EditAssetImageDelete(
            form_data,
            source
          );
          if (response?.status === 200) {
            successToast("Image deleted successfully");
            let updatedData = data.filter((item, index) => index !== value);
            setData(updatedData);
          } else {
            errorToast(response.msg);
          }
        } catch (error) {
          console.log(error);
          errorToast(error?.message);
        }
      },
    });
  };

  const handlePrimaryImage = (e, value, imageDataIndex) => {
    e.preventDefault();

    const form_data = new FormData();
    form_data.append("primary", value?.id);

    Swal.fire({
      title: "Are you sure?",
      html: `<p>You want to make this picture as primary!</p>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await auctionService.EditAssetImagePrimary(
            form_data,
            source
          );
          if (response?.status === 200) {
            const newData = data.map((img, index) => ({
              ...img,
              primary: index === imageDataIndex,
            }));
            setCheckedItemsEditPrimary(newData);
            setData(newData);
          } else {
            errorToast(response.msg);
          }
        } catch (error) {
          console.log(error);
          errorToast(error?.message);
        }
      },
    });
  };

  return (
    <>
      <div className="container-fluid shadow-sm mb-2">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-3 mb-2">
          <span className="d-flex justify-content-between col-1">
            <i
              className="bi bi-arrow-left-circle"
              onClick={() =>
                history.push(
                  `/meratractor/auctions/modify/asset?ref_no=${asset_id}&uid=${uid}`
                )
              }
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </span>
          <div className="col-10 mt-2 float-start ms-2">
            <h5 className="mt-2">{asset_id}</h5>
          </div>
        </div>
      </div>

      <div className="container d-flex d-flex-wrap justify-content-center rounded">
        <div
          className={
            data && data?.length >= 0
              ? "col-12 bg-body p-3 mb-2 rounded"
              : "col-12 "
          }
          style={{
            marginTop: "2rem",
          }}
        >
          <div className="container-fluid mt-3">
            <form className="ms-auto">
              {loading ? (
                <div
                  className="d-flex justify-content-between container"
                  style={{ minHeight: "80vh", alignItems: "center" }}
                >
                  <ReactLoading
                    type={"bubbles"}
                    color={"black"}
                    height={100}
                    width={100}
                    className="reactLoadingDash "
                  />
                </div>
              ) : (
                <>
                  {data && data.length >= 0 && (
                    <>
                      <div className="row mt-4">
                        <div className="container-fluid">
                          <div className="row">
                            {data &&
                              data.map((value, imageDataIndex, key) => (
                                <div className="col-12 col-sm-3 col-md-4 col-lg-2 form-group mb-4">
                                  <div
                                    className="imagePreview"
                                    style={{
                                      position: "relative",
                                      height: "17rem",
                                    }}
                                  >
                                    <i
                                      className="bi bi-x  del"
                                      onClick={(e) =>
                                        handleDelete(e, value, imageDataIndex)
                                      }
                                      style={{
                                        position: "absolute",
                                        top: "-15px",
                                        right: "-15px",
                                        fontSize: "24px",
                                        height: "30px",
                                        width: "30px",
                                        cursor: "pointer",
                                        color: "white",
                                        backgroundColor: "#E97451",
                                        zIndex: "5",
                                        borderRadius: "70%",
                                        backgroundPosition: "center",
                                      }}
                                    ></i>
                                    <img
                                      src={value?.image_link}
                                      className="card-img-top"
                                      alt="auction"
                                      style={{
                                        width: "100%",
                                        height: "12rem",
                                      }}
                                    />

                                    <div className="card-body">
                                      <div className="form-check mt-4 ms-2">
                                        <label className="form-check-label ">
                                          Set as Primary
                                        </label>
                                        <input
                                          style={{ cursor: "pointer" }}
                                          className="form-check-input"
                                          type="radio"
                                          checked={value["primary"]}
                                          onChange={(e) => {
                                            handlePrimaryImage(
                                              e,
                                              value,
                                              imageDataIndex
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAssetImage;
