// originalCode.js
import axios from "axios";
import tokenManager from "./tokenManager";
import { errorToast } from "../react-toastfiy/toast";
import jwtDecode from "jwt-decode";
// import { errorToast } from "../react-toastfiy/toast";

axios.defaults.baseURL = "https://erpbackend.meratractor.com";
// axios.defaults.baseURL = "http://localhost:8000";
// axios.defaults.baseURL = "http://localhost:8001";
// axios.defaults.baseURL = "http://192.168.1.9:8000";
// axios.defaults.baseURL = "http://192.168.1.20:8000";
// let is_refresh = false;
// let requestQueue = [];

/*
axios.interceptors.request.use(
  async (config) => {
    if (config.url !== "/login") {
      if (is_refresh) {
        // If a token refresh is already in progress, wait for it to complete.
        if (config.url !== "/refreshToken") {
          return new Promise((resolve) => {
            requestQueue.push({ config, resolve });
          });
        }
      }
      const token_valid = tokenManager.isTokenValid(config);
      console.log(token_valid);
      if (!token_valid) {
        is_refresh = true;
        try {


          
          // Add your logic to make the refresh token request and obtain a new access token.
          const response = await axios.post(
            "/refreshToken",
            {},
            {
              headers: {
                Authorization: localStorage.getItem("refreshToken"),
              },
            }
          );
          if (response.data.status === 200) {
            const newAccessToken = response.data.token;
            axios.defaults.headers.common["Authorization"] = newAccessToken;

            // Resolve all the queued requests with the new access token.
            requestQueue.forEach((request) => {
              // request.config.headers["Authorization"] = `${newAccessToken}`;
              // Re-send the original request.
              axios(request.config).then(request.resolve);
            });
            requestQueue = [];
            return config;
          } else {
            errorToast(response.data.msg);
          }
        } catch (error) {
          // Handle any error that may occur during the token refresh process.
          // Log the user out and clear the queue on error.
          localStorage.clear();
          requestQueue = [];
          window.location.replace("/sign-in");
        } finally {
          is_refresh = false;
        }
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
*/

// axios.interceptors.request.use(async (resp) => {
//   console.log("fethcing refresToken ", window.fetchingRefreshToken);
//   if (resp.data.status === 401 && resp.config.url !== "/refreshToken") {
//     try {
//       const newAccessToken = await tokenManager.refreshToken();
//       if (newAccessToken) {
//         resp.config.headers["Authorization"] = newAccessToken;
//         return axios(resp.config);
//       }
//     } catch (error) {
//       // Handle any error that may occur during the token refresh process.
//       // Log the user out and clear the queue on error.
//       localStorage.clear();
//       // window.location.replace("/sign-in");
//       tokenManager.clearQueue();
//       console.log(error);
//     }
//   } else if (resp.data.status === 401 && resp.config.url === "/refreshToken") {
//     localStorage.clear();
//     tokenManager.clearQueue();
//     window.location.replace("/sign-in");
//   }
//   return resp;
// });

/*
axios.interceptors.response.use(async (resp) => {
  if (resp.data.status === 401 && resp.config.url !== "/refreshToken") {
    try {
      const newAccessToken = await tokenManager.refreshToken();
      if (newAccessToken) {
        resp.config.headers["Authorization"] = newAccessToken;
        return axios(resp.config);
      }
    } catch (error) {
      // Handle any error that may occur during the token refresh process.
      // Log the user out and clear the queue on error.
      localStorage.clear();
      // window.location.replace("/sign-in");
      tokenManager.clearQueue();
    }
  } else if (resp.data.status === 401 && resp.config.url === "/refreshToken") {
    localStorage.clear();
    tokenManager.clearQueue();
    window.location.replace("/sign-in");
  }
  return resp;
});
*/

const excludedURLs = ["/refreshToken", "/login"];

// const MT_URL = "http://192.168.0.130:8001";
// const MT_URL = "http://localhost:8000";
// const MT_URL = "http://localhost:8001";
const MT_URL = "https://test.meratractor.com";
// const MT_URL = "https://test.meratractor.com";
// const MT_URL = "https://192.168.1.5:8000";

// console.log(axios.interceptors.request.use((config) => config?.headers));

axios.interceptors.request.use(async (config) => {
  let token = config?.headers?.Authorization
    ? config?.headers?.Authorization
    : config?.headers?.common?.Authorization;

  if (token) {
    const decoded_access_token_exp_time = jwtDecode(token).exp;
    const current_time_second = Math.round(new Date() / 1000);
    if (decoded_access_token_exp_time <= current_time_second) {
      token = undefined;
    }
  }
  const refreshToken = localStorage.getItem("refreshToken");
  if (
    refreshToken &&
    !token &&
    !window.fetchingRefreshToken &&
    (!excludedURLs.includes(config.url) || config.url.startsWith(MT_URL))
  ) {
    window.tokenAboutToExpire = true;
    window.fetchingRefreshToken = true;
    try {
      const response = await axios.post(
        "/refreshToken",
        {},
        {
          headers: {
            Authorization: refreshToken,
          },
        }
      );

      if (response.data.status === 200) {
        const newAccessToken = response.data.token;
        axios.defaults.headers.common["Authorization"] = newAccessToken;
        window.tokenAboutToExpire = false;
        window.fetchingRefreshToken = false;

        window.requestQueue.forEach(({ resolve, config }) => {
          resolve({
            ...config,
            headers: { ...config?.headers, Authorization: newAccessToken },
          });
        });
        window.requestQueue = [];

        return {
          ...config,
          headers: { ...config?.headers, Authorization: newAccessToken },
        };
      } else if (response.data.status === 401) {
        localStorage.clear();
        window.location.replace("/sign-in");
        window.tokenAboutToExpire = false;
        window.fetchingRefreshToken = false;
        window.requestQueue = []; // Clear
      } else {
        errorToast(response.data.msg);
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      console.log({ error });
      window.tokenAboutToExpire = false;
      window.fetchingRefreshToken = false;
      window.requestQueue = []; // Clear
    }
  }

  if (
    !window.tokenAboutToExpire ||
    excludedURLs.includes(config.url) ||
    config.url.startsWith(MT_URL)
  ) {
    return config;
  } else {
    return new Promise((resolve) => {
      window.requestQueue.push({ resolve, config });
    });
  }
});

axios.interceptors.response.use((resp) => {
  if (
    resp?.data?.status === 401 &&
    !excludedURLs?.includes(resp?.config?.url)
  ) {
    console.log({ resp });
    errorToast(resp?.data?.msg);
    // localStorage.clear();
    // window.location.replace("/sign-in");
    // window.tokenAboutToExpire = false;
    // window.fetchingRefreshToken = false;
    // window.requestQueue = [];
    return resp;
  } else {
    return resp;
  }
});
