import Joi, { join } from "joi-browser";

export const StateSchema = {
  state_name: Joi.string().required().label("State"),
  state_shorthand: Joi.string().required().label("Shorthand"),
};

export const DistrictSchema = {
  state_id: Joi.number().required().label("State"),
  district_name: Joi.string().required().label("District"),
  state_name: Joi.string().options({ allowUnknown: true }),
};

export const TehsilSchema = {
  state_id: Joi.number().required().label("State"),
  district_id: Joi.number().required().label("District"),
  tehsil_name: Joi.string().required().label("Tehsil"),
};

export const ClusterSchema = {
  cluster_name: Joi.string().required().label("Cluster Name"),
  state: Joi.array().items(Joi.string()),
  district: Joi.array().items(Joi.string()),
  tehsil: Joi.array().items(Joi.string()),
};

export const DesignationSchema = {
  business_id: Joi.string().required().label("Business"),
  designation_name: Joi.string().required().label("Designation"),
  designation_shorthand: Joi.string().required().label("Shorthand"),
};

export const AddUserSchema = {
  first_name: Joi.string().required().label("First Name"),
  middle_name: Joi.string().label("Middle Name").allow("", null),
  last_name: Joi.string().required().label("Last Name"),
  phone: Joi.string().length(10).required().label("Phone"),
  password: Joi.string().required().label("Password"),
  repeat_password: Joi.ref("password"),
  address: Joi.string().required().label("Address"),
  email: Joi.string().email().required().label("Email"),
  pincode: Joi.string().required().label("Pincode"),
  pan: Joi.string().required().label("Pan"),
  aadhar: Joi.string().required().label("Aadhar"),
  pan_file: Joi.required().label("Pan File"),
  front_aadhar_file: Joi.required().label("Front Aadhar File"),
  back_aadhar_file: Joi.required().label("Front Aadhar File"),
  // additional_designations: Joi.array()
  //   .required()
  //   .min(1)
  //   .label("User Designation"),
  user_designation: Joi.number().label("User Designation"),
  reporting_personal: Joi.number().label("Reporting Personal").allow("", null),
  assign_cluster: Joi.array().options({ allowUnknown: true }).allow("", null),
  state: Joi.number().label("State"),
  account_number: Joi.string().label("Account Number").allow("", null),
  ifsc: Joi.string().label("IFSC Code").allow("", null),
  bank_name: Joi.string().label("Bank Name").allow("", null),
  branch_name: Joi.string().label("Branch Name").allow("", null),
  account_name: Joi.string().label("Account Holder Name").allow("", null),
  is_blocked: Joi.boolean().label("Is Blocked").allow("", null),
  allow_attendance_mark: Joi.boolean()
    .label("allow_attendance_mark")
    .allow("", null),
  assigned_states_designations: Joi.array()
    .items({
      state: Joi.string().required(),
      designations: Joi.array().required().min(1),
    })
    .required()
    .label("State Wise Designation"),
};

export const EditUserSchema = {
  first_name: Joi.string().required().label("First Name"),
  middle_name: Joi.string().label("Middle Name").allow("", null),
  last_name: Joi.string().required().label("Last Name"),
  phone: Joi.string().length(10).required().label("Phone"),
  address: Joi.string().required().label("Address"),
  email: Joi.string().email().required().label("Email"),
  pincode: Joi.string().required().label("Pincode"),
  pan: Joi.string().required().label("Pan"),
  aadhar: Joi.string().required().label("Aadhar"),
  pan_file: Joi.required().label("Pan File"),
  front_aadhar_file: Joi.required().label("Front Aadhar File"),
  back_aadhar_file: Joi.required().label("Front Aadhar File"), //   .required()
  //   .min(1)
  //   .label("User Designation"),
  assigned_states_designations: Joi.array()
    .items({
      state: Joi.string().required(),
      designations: Joi.array().required().min(1),
    })
    .required()
    .label("State Wise Designation"),
  user_designation: Joi.number().label("User Designation"),
  user_designation_id: Joi.number().label("User Designation Id"),
  reporting_personal: Joi.object().required().label("Reporting Personal"),
  reporting_personal_id: Joi.number()
    .label("Reporting Personal Id")
    .allow("", null),
  assign_cluster: Joi.array().options({ allowUnknown: true }).allow("", null),
  state: Joi.number().label("State").allow("", null),
  stateDic: Joi.object().label("State").allow("", null),
  account_number: Joi.string().label("Account Number").allow("", null),
  ifsc: Joi.string().label("IFSC Code").allow("", null),
  bank_name: Joi.string().label("Bank Name").allow("", null),
  branch_name: Joi.string().label("Branch Name").allow("", null),
  account_name: Joi.string().label("Account Holder Name").allow("", null),
  is_blocked: Joi.boolean().label("Is Active").allow("", null),
  allow_attendance_mark: Joi.boolean()
    .label("allow_attendance_mark")
    .allow("", null),
};

export const AddTractorpointSchema = {
  name: Joi.string().required().label("Tractorpoint Name"),
  state_id: Joi.string().required().label("State"),
  district_id: Joi.string().required().label("District"),
  tehsil_id: Joi.string().required().label("Tehsil").allow("", null),
  fo_id: Joi.string().required().label("Franchise Owner").allow("", null),
  tpId: Joi.string().required().label("Tractorpoint Id"),
  assign_id: Joi.number().required().label("Assign To"),
  lat: Joi.number().options({ allowUnknown: true }),
  long: Joi.number().options({ allowUnknown: true }),
  acc_user_id: Joi.object().label("Account Personnel"),
  rto_user_id: Joi.object().label("RTO Personnel"),
  auctionRep_user_id: Joi.object().label("Auction Representative"),
  // tp_location:Joi.string().label("Tractorpoint Location").allow("", null),
  // tp_type:Joi.string().label("Tractorpoint Type").allow("", null),
  tp_location: Joi.string().required().label("Tractorpoint Location"),
  tp_type: Joi.object().allow("", null),
  account_number: Joi.string().label("Account Number").allow("", null),
  ifsc: Joi.string().label("IFSC Code").allow("", null),
  bank_name: Joi.string().label("Bank Name").allow("", null),
  branch_name: Joi.string().label("Branch Name").allow("", null),
  account_name: Joi.string().label("Account Holder Name").allow("", null),
  tractorpoint_dealer: Joi.boolean()
    .label("Tractorpoint Dealer")
    .allow("", null),
  implement_dealer: Joi.boolean().label("Implement Dealer").allow("", null),
  state_office: Joi.object().allow("", null),
  state_office_id: Joi.string().allow("", null),
};

export const EditTractorpointSchema = {
  name: Joi.string().required().label("Tractorpoint Name"),
  state_id: Joi.string().required().label("State"),
  district_id: Joi.string().required().label("District"),
  tehsil_id: Joi.string().required().label("Tehsil"),
  fo_id: Joi.string().label("Franchise Owner").allow("", null),
  acc_user_id: Joi.object().allow("", null),
  rto_user_id: Joi.object().allow("", null),
  assign_id: Joi.number().required().label("Assign To"),
  lat: Joi.number().options({ allowUnknown: true }),
  long: Joi.number().options({ allowUnknown: true }),
  district: Joi.string().required().options({ allowUnknown: true }),
  tehsil: Joi.array().required().options({ allowUnknown: true }),
  state: Joi.string().required().options({ allowUnknown: true }),
  no: Joi.number().options({ allowUnknown: true }),
  id: Joi.number().options({ allowUnknown: true }),
  tp_id: Joi.string().required().options({ allowUnknown: true }),
  sh: Joi.string().allow("", null),
  tm: Joi.string().allow("", null),
  rm: Joi.string().allow("", null),
  auctionRep_user_id: Joi.object().allow("", null),
  tp_location: Joi.string().required().label("Tractorpoint Location"),
  tp_type: Joi.object().allow("", null),
  account_number: Joi.string().label("Account Number").allow("", null),
  ifsc: Joi.string().label("IFSC Code").allow("", null),
  bank_name: Joi.string().label("Bank Name").allow("", null),
  branch_name: Joi.string().label("Branch Name").allow("", null),
  account_name: Joi.string().label("Account Holder Name").allow("", null),
  tractorpoint_dealer: Joi.boolean()
    .label("Tractorpoint Dealer")
    .allow("", null),
  implement_dealer: Joi.boolean().label("Implement Dealer").allow("", null),
  state_office: Joi.object().allow("", null),
  state_office_id: Joi.string().allow("", null),
};

export const MakeSchema = {
  make: Joi.string().required().label("Make"),
  logo: Joi.any().valid(null, ""),
};

export const ModelSchema = {
  make: Joi.string().required().label("Make"),
  model: Joi.string().required().label("Model"),
  make_id: Joi.number(),
};

export const AddTractorSchema = {
  loanNo: Joi.string().required().label("Loan No"),
  chasisNo: Joi.string().required().label("Chasis No"),
  regNo: Joi.string().required().label("Registration No"),
  make: Joi.string().required().label("Make"),
  model: Joi.string().required().label("Model"),
  year: Joi.string().required().label("Year"),
  csp: Joi.string().required().label("Cost of Purchase"),
  approvalDate: Joi.string().required().label("Approval Date"),
};

export const CommisionAgentSchema = {
  id: Joi.label("id"),
  name: Joi.string().required().label("name"),
  manufacturer: Joi.object().required().label("manufacturer"),
  location_pincode: Joi.number().required().label("location_pincode"),
  state_id: Joi.object().required().label("state"),
  district_id: Joi.object().required().label("district"),
  tehsil_id: Joi.object().required().label("tehsil"),
  commision_agent: Joi.label("commision agent"),
  phone: Joi.number().required().label("phone"),
  pan: Joi.label("pan"),
  procurement_rm: Joi.object().required().label("procurement rm"),
  gst_certificate: Joi.label("gst certificate"),
  gst_certificate_name: Joi.label("gst certificate"),
};

export const ExchangeDealerCodeSchema = {
  id: Joi.label("id"),
  name: Joi.string().required().label("name"),
  dealer_code: Joi.string().required().label("dealer_code"),
  manufacturer: Joi.object().required().label("manufacturer"),
  location_pincode: Joi.number().required().label("location_pincode"),
  am: Joi.object().required().label("am"),
  tm: Joi.label("tm").allow("", null),
  state_dict: Joi.object().required().label("state"),
  state: Joi.string().label("state").allow("", null),
  district_dict: Joi.object().required().label("district"),
  district: Joi.string().label("district").allow("", null),
  tehsil_dict: Joi.object().required().label("tehsil"),
  tehsil: Joi.string().label("tehsil").allow("", null),
  promoter: Joi.label("promoter").allow("", null),
  mobile_promoter: Joi.number().required().label("phone").allow("", null),
  manager: Joi.label("manager").allow("", null),
  mobile_manager: Joi.number().label("manager mobile").allow("", null),
  pan_dealership: Joi.label("pan").allow("", null),
  gst_certificate: Joi.label("gst certificate").allow("", null),
  gst_certificate_name: Joi.label("gst certificate").allow("", null),
  dealership_picture: Joi.label("gst certificate").allow("", null),
  dealership_picture_name: Joi.label("gst certificate").allow("", null),
  gst_number: Joi.label("gst_number").allow("", null),
};

// export const SubscriptionSchemaUpgrade = {
//   plan_name: Joi.string().required().label("Plan Name"),
//   platform_type: Joi.string().required().label("platform Type"),

//   plan_term: Joi.object({
//     monthly: Joi.string().allow("", null),
//     quarterly: Joi.string().allow("", null),
//     yearly: Joi.string().allow("", null),
//   }),
//   plan_id: Joi.string().allow("", null),
//   add_on_plans: Joi.array().allow("", null),
//   plan_type: Joi.string().required().label("Plan Type"),
//   plan_category: Joi.string().required().label("Plan category"),
//   upgrade_from: Joi.string().required().label("Upgrade From"),
//   upgrade_to: Joi.string().required().label("Upgrade To"),
//   upgrade_cost: Joi.string().required().label("Upgrade Cost"),
//   description: Joi.object({
//     data: Joi.array().min(1).required().label("Description"),
//   }),
//   included_items: Joi.object({
//     data: Joi.array().min(1).required().label("Included Item"),
//   }),

//   excluded_items: Joi.object({
//     data: Joi.array().min(1).required().label("Excluded Item"),
//   }),
//   module_permissions: Joi.object({
//     is_buyer: Joi.boolean().allow("", null),
//     is_seller: Joi.boolean().allow("", null),
//     is_auction_approval: Joi.boolean().allow("", null),
//   }),

//   state_permissions: Joi.object({
//     user_state: Joi.boolean().allow("", null),
//     multistate: Joi.object({
//       selected: Joi.boolean().allow("", null),
//       total_add_on: Joi.string().allow("", null),
//     }),
//     all_state_access: Joi.boolean().allow("", null),
//   }),
//   allow_trials: Joi.object({
//     selected: Joi.boolean().allow("", null),
//     trial_days: Joi.string().allow("", null),
//   }),
//   plan_type_state: Joi.number().label("plan_type_state").allow("", null),
//   is_recommended: Joi.boolean().allow("", null),
//   is_active: Joi.boolean().allow("", null),
//   is_disabled: Joi.boolean().allow("", null),
//   is_free: Joi.boolean().allow("", null),
//   draft: Joi.boolean().allow("", null),
// };
// export const SubscriptionSchema = {
//   plan_name: Joi.string().required().label("Plan Name"),
//   platform_type: Joi.string().required().label("platform Type"),
//   plan_type_state: Joi.string().allow("", null).allow("", null),
//   add_on_plans: Joi.array().label("Assign Plan").allow("", null),
//   plan_type: Joi.string().required().label("Plan Type"),
//   plan_category: Joi.string(),
//   plan_id: Joi.string().allow("", null),
//   plan_term: Joi.object({
//     yearly: Joi.string(),
//     quarterly: Joi.string(),
//     monthly: Joi.string(),
//   }),

//   upgrade_from: Joi.string().allow("", null),
//   upgrade_to: Joi.string().allow("", null),
//   upgrade_cost: Joi.number().allow("", null),

//   description: Joi.object({
//     data: Joi.array().min(1).required().label("Description"),
//   }),
//   included_items: Joi.object({
//     data: Joi.array().min(1).required().label("Included Item"),
//   }),

//   excluded_items: Joi.object({
//     data: Joi.array().min(1).required().label("Excluded Item"),
//   }),
//   module_permissions: Joi.object({
//     is_buyer: Joi.boolean().allow("", null),
//     is_seller: Joi.boolean().allow("", null),
//     is_auction_approval: Joi.boolean().allow("", null),
//   }),

//   state_permissions: Joi.object({
//     user_state: Joi.boolean().allow("", null),
//     multistate: Joi.object({
//       selected: Joi.boolean().allow("", null),
//       total_add_on: Joi.string().allow("", null),
//     }),
//     all_state_access: Joi.boolean().allow("", null),
//   }),
//   allow_trials: Joi.object({
//     selected: Joi.boolean().allow("", null),
//     trial_days: Joi.string().allow("", null),
//   }),
//   is_recommended: Joi.boolean(),
//   is_active: Joi.boolean(),
//   draft: Joi.boolean(),
//   is_disabled: Joi.boolean(),
//   is_free: Joi.boolean(),
// };
// export const SubscriptionSchemaStatePermission = {
//   plan_name: Joi.string().required().label("Plan Name"),
//   platform_type: Joi.string().required().label("platform Type"),
//   plan_category: Joi.string().required().label("Plan category"),
//   plan_term: Joi.object({
//     yearly: Joi.string().required().label("Yearly Plan Cost"),
//     quarterly: Joi.string().required().label("Quarterly Plan Cost"),
//     monthly: Joi.string().required().label("Monthly Plan Cost"),
//   }),
//   plan_id: Joi.string().allow("", null),
//   description: Joi.object({
//     data: Joi.array().min(1).required().label("Description"),
//   }),

//   included_items: Joi.object({
//     data: Joi.array().min(1).required().label("Included Item"),
//   }),
//   excluded_items: Joi.object({
//     data: Joi.array().min(1).required().label("Excluded Item"),
//   }),

//   module_permissions: Joi.object({
//     is_buyer: Joi.boolean(),
//     is_seller: Joi.boolean(),
//     is_auction_approval: Joi.boolean(),
//   })
//     .or("is_buyer", "is_seller", "is_auction_approval")
//     .required(),

//   state_permissions: Joi.object({
//     user_state: Joi.boolean().allow("", null),
//     multistate: Joi.object({
//       selected: Joi.boolean().allow("", null),
//       total_add_on: Joi.string().allow("", null),
//     }),
//     all_state_access: Joi.boolean().allow("", null),
//   }),

//   plan_type_state: Joi.number().required().label("State Permission"),

//   add_on_plans: Joi.array().allow("", null),
//   plan_type: Joi.string().required().label("Plan Type"),
//   upgrade_from: Joi.string().allow("", null),
//   upgrade_to: Joi.string().allow("", null),
//   upgrade_cost: Joi.number().allow("", null),

//   allow_trials: Joi.object({
//     selected: Joi.boolean().allow("", null),
//     trial_days: Joi.string().allow("", null),
//   }),
//   is_recommended: Joi.boolean().allow("", null),
//   is_active: Joi.boolean().allow("", null),
//   is_disabled: Joi.boolean().allow("", null),
//   is_free: Joi.boolean().allow("", null),
//   draft: Joi.boolean().allow("", null),
// };
// export const SubscriptionSchemaAddOn = {
//   plan_name: Joi.string().required().label("Plan Name"),
//   platform_type: Joi.string().required().label("platform Type"),
//   plan_type: Joi.string().required().label("Plan Type"),
//   plan_id: Joi.string().allow("", null),

//   add_on_plans: Joi.array().min(1).required().label("Assign plan"),
//   plan_category: Joi.string().required().label("Plan category"),
//   plan_term: Joi.object({
//     yearly: Joi.string().required().label("Yearly Plan Cost"),
//     quarterly: Joi.string().required().label("Quarterly Plan Cost"),
//     monthly: Joi.string().required().label("Monthly Plan Cost"),
//   }),
//   upgrade_from: Joi.string().allow("", null),
//   upgrade_to: Joi.string().allow("", null),
//   upgrade_cost: Joi.number().allow("", null),
//   description: Joi.object({
//     data: Joi.array().min(1).required().label("Description"),
//   }),
//   included_items: Joi.object({
//     data: Joi.array().min(1).required().label("Included Item"),
//   }),

//   excluded_items: Joi.object({
//     data: Joi.array().min(1).required().label("Excluded Item"),
//   }),
//   module_permissions: Joi.object({
//     is_buyer: Joi.boolean().allow("", null),
//     is_seller: Joi.boolean().allow("", null),
//     is_auction_approval: Joi.boolean().allow("", null),
//   }),

//   state_permissions: Joi.object({
//     user_state: Joi.boolean().allow("", null),
//     multistate: Joi.object({
//       selected: Joi.boolean().allow("", null),
//       total_add_on: Joi.string().allow("", null),
//     }),
//     all_state_access: Joi.boolean().allow("", null),
//   }),
//   allow_trials: Joi.object({
//     selected: Joi.boolean().allow("", null),
//     trial_days: Joi.string().allow("", null),
//   }),
//   plan_type_state: Joi.number().label("plan_type_state").allow("", null),
//   is_recommended: Joi.boolean().allow("", null),
//   is_active: Joi.boolean().allow("", null),
//   draft: Joi.boolean().allow("", null),
//   is_disabled: Joi.boolean().allow("", null),
//   is_free: Joi.boolean().allow("", null),
// }
export const IsSubscribedUser = {
  is_subscribed: Joi.boolean().required(),
  subscription_date: Joi.string().required().label("Subscription date"),
  subscription_tenure: Joi.number().required().label("Subscription tenure"),
  payment_date: Joi.string().required().label("Payment date"),
  payment_proof: Joi.required().label("Payment proof"),
  payment_amount: Joi.number().required().label("Payment amount"),
  utr: Joi.string().required().label("UTR number"),
};

const dynamicPlanTermSchema = Joi.object()
  .pattern(
    /^(?:[1-9]|[1-9][0-9]|100) month$/,
    Joi.string().allow("", null).label("Plan Term")
  )
  .min(1)
  .required()
  .label("Plan Terms");

export const SubscriptionSchemaUpgrade = {
  plan_name: Joi.string().required().label("Plan Name"),
  platform_type: Joi.string().required().label("platform Type"),
  plan_term: Joi.object({
    monthly: Joi.string().allow("", null),
    quarterly: Joi.string().allow("", null),
    yearly: Joi.string().allow("", null),
  }),
  plan_id: Joi.string().allow("", null),
  add_on_plans: Joi.array().allow("", null),
  plan_type: Joi.string().required().label("Plan Type"),
  plan_category: Joi.string().required().label("Plan category"),
  upgrade_from: Joi.string().required().label("Upgrade From"),
  upgrade_to: Joi.string().required().label("Upgrade To"),
  upgrade_cost: Joi.string().required().label("Upgrade Cost"),
  description: Joi.object({
    data: Joi.array().min(1).required().label("Description"),
  }),
  included_items: Joi.object({
    data: Joi.array().min(1).required().label("Included Item"),
  }),

  excluded_items: Joi.object({
    data: Joi.array().min(1).required().label("Excluded Item"),
  }),
  module_permissions: Joi.object({
    is_buyer: Joi.boolean().allow("", null),
    is_seller: Joi.boolean().allow("", null),
    is_auction_approval: Joi.boolean().allow("", null),
  }),

  state_permissions: Joi.object({
    user_state: Joi.boolean().allow("", null),
    multistate: Joi.object({
      selected: Joi.boolean().allow("", null),
      total_add_on: Joi.string().allow("", null),
    }),
    all_state_access: Joi.boolean().allow("", null),
  }),
  allow_trials: Joi.object({
    selected: Joi.boolean().allow("", null),
    trial_days: Joi.string().allow("", null),
  }),
  plan_type_state: Joi.number().label("plan_type_state").allow("", null),
  is_recommended: Joi.boolean().allow("", null),
  is_active: Joi.boolean().allow("", null),
  is_disabled: Joi.boolean().allow("", null),
  is_free: Joi.boolean().allow("", null),
  draft: Joi.boolean().allow("", null),
};
export const SubscriptionSchema = {
  plan_name: Joi.string().required().label("Plan Name"),
  platform_type: Joi.string().required().label("platform Type"),
  plan_type_state: Joi.string().allow("", null).allow("", null),
  add_on_plans: Joi.array().label("Assign Plan").allow("", null),
  plan_type: Joi.string().required().label("Plan Type"),
  plan_category: Joi.string(),
  plan_id: Joi.string().allow("", null),
  plan_term: dynamicPlanTermSchema,

  upgrade_from: Joi.string().allow("", null),
  upgrade_to: Joi.string().allow("", null),
  upgrade_cost: Joi.number().allow("", null),

  description: Joi.object({
    data: Joi.array().min(1).required().label("Description"),
  }),
  included_items: Joi.object({
    data: Joi.array().min(1).required().label("Included Item"),
  }),

  excluded_items: Joi.object({
    data: Joi.array().min(1).required().label("Excluded Item"),
  }),
  module_permissions: Joi.object({
    is_buyer: Joi.boolean().allow("", null),
    is_seller: Joi.boolean().allow("", null),
    is_auction_approval: Joi.boolean().allow("", null),
  }),

  state_permissions: Joi.object({
    user_state: Joi.boolean().allow("", null),
    multistate: Joi.object({
      selected: Joi.boolean().allow("", null),
      total_add_on: Joi.string().allow("", null),
    }),
    all_state_access: Joi.boolean().allow("", null),
  }),
  allow_trials: Joi.object({
    selected: Joi.boolean().allow("", null),
    trial_days: Joi.string().allow("", null),
  }),
  is_recommended: Joi.boolean(),
  is_active: Joi.boolean(),
  draft: Joi.boolean(),
  is_disabled: Joi.boolean(),
  is_free: Joi.boolean(),
};
export const SubscriptionSchemaStatePermission = {
  plan_name: Joi.string().required().label("Plan Name"),
  platform_type: Joi.string().required().label("platform Type"),
  plan_category: Joi.string().required().label("Plan category"),
  plan_term: dynamicPlanTermSchema,
  plan_id: Joi.string().allow("", null),
  description: Joi.object({
    data: Joi.array().min(1).required().label("Description"),
  }),

  included_items: Joi.object({
    data: Joi.array().min(1).required().label("Included Item"),
  }),
  excluded_items: Joi.object({
    data: Joi.array().min(1).required().label("Excluded Item"),
  }),

  module_permissions: Joi.object({
    is_buyer: Joi.boolean(),
    is_seller: Joi.boolean(),
    is_auction_approval: Joi.boolean(),
  })
    .or("is_buyer", "is_seller", "is_auction_approval")
    .required(),

  state_permissions: Joi.object({
    user_state: Joi.boolean().allow("", null),
    multistate: Joi.object({
      selected: Joi.boolean().allow("", null),
      total_add_on: Joi.string().allow("", null),
    }),
    all_state_access: Joi.boolean().allow("", null),
  }),

  plan_type_state: Joi.number().required().label("State Permission"),

  add_on_plans: Joi.array().allow("", null),
  plan_type: Joi.string().required().label("Plan Type"),
  upgrade_from: Joi.string().allow("", null),
  upgrade_to: Joi.string().allow("", null),
  upgrade_cost: Joi.number().allow("", null),

  allow_trials: Joi.object({
    selected: Joi.boolean().allow("", null),
    trial_days: Joi.string().allow("", null),
  }),
  is_recommended: Joi.boolean().allow("", null),
  is_active: Joi.boolean().allow("", null),
  is_disabled: Joi.boolean().allow("", null),
  is_free: Joi.boolean().allow("", null),
  draft: Joi.boolean().allow("", null),
};
export const SubscriptionSchemaAddOn = {
  plan_name: Joi.string().required().label("Plan Name"),
  platform_type: Joi.string().required().label("platform Type"),
  plan_type: Joi.string().required().label("Plan Type"),
  plan_id: Joi.string().allow("", null),

  add_on_plans: Joi.array().min(1).required().label("Assign plan"),
  plan_category: Joi.string().required().label("Plan category"),
  plan_term: dynamicPlanTermSchema,
  upgrade_from: Joi.string().allow("", null),
  upgrade_to: Joi.string().allow("", null),
  upgrade_cost: Joi.number().allow("", null),
  description: Joi.object({
    data: Joi.array().min(1).required().label("Description"),
  }),
  included_items: Joi.object({
    data: Joi.array().min(1).required().label("Included Item"),
  }),

  excluded_items: Joi.object({
    data: Joi.array().min(1).required().label("Excluded Item"),
  }),
  module_permissions: Joi.object({
    is_buyer: Joi.boolean().allow("", null),
    is_seller: Joi.boolean().allow("", null),
    is_auction_approval: Joi.boolean().allow("", null),
  }),

  state_permissions: Joi.object({
    user_state: Joi.boolean().allow("", null),
    multistate: Joi.object({
      selected: Joi.boolean().allow("", null),
      total_add_on: Joi.string().allow("", null),
    }),
    all_state_access: Joi.boolean().allow("", null),
  }),
  allow_trials: Joi.object({
    selected: Joi.boolean().allow("", null),
    trial_days: Joi.string().allow("", null),
  }),
  plan_type_state: Joi.number().label("plan_type_state").allow("", null),
  is_recommended: Joi.boolean().allow("", null),
  is_active: Joi.boolean().allow("", null),
  draft: Joi.boolean().allow("", null),
  is_disabled: Joi.boolean().allow("", null),
  is_free: Joi.boolean().allow("", null),
};
