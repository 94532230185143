import axios from "axios";
import { errorToast } from "../react-toastfiy/toast";

class ViewDashboard {
  getDashDataById = (id) => {
    return axios({
      method: "post",
      url: `/dashboard`,
      data: id,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getTaskData = () => {
    return axios({
      method: "post",
      url: `/operations/get-tasks`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  saveTaskData = (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-tasks`,
      body: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getTaskDataById = (id) => {
    return axios({
      method: "post",
      url: `/operations/get-tasks`,
      body: id,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getMyTask = (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-my-tasks`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getSubTask = (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-sub-tasks`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getDashMilestoneData = (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-dash-milestone-data`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getSingleTask = (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-single-task`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  saveDataToBeCollected = (formData) => {
    return axios({
      method: "post",
      url: `/operations/save-data-to-be-collected`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  markAsCompleted = (formData) => {
    return axios({
      method: "post",
      url: `/operations/mark-as-completed`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  deleteTask = (formData) => {
    return axios({
      method: "post",
      url: `/operations/delete-task`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getDataToBeCollected = (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-data-to-be-collected`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  listSubordinates = (formData) => {
    return axios({
      method: "post",
      url: `/operations/list-subordinates`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  reassignTask = (formData) => {
    return axios({
      method: "post",
      url: `/operations/reassign-task`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getUserDetailsByPhone = (formData) => {
    return axios({
      method: "post",
      url: `/users/get-users-details-by-phone`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getAllActivitiesName = (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-all-activities-name`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getAllActivityData = (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-completed-task`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getAllSubordinates = (formData) => {
    return axios({
      method: "post",
      url: `/operations/list-subordinates`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getAllActivityDataByDate = (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-completed-task-date`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getAllFilteredDataByDate = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-all-completed-task-date`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getTaskUsers = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-task-users`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getReportFormId = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-report-form-id`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getReportSchema = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-report-schema`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getSuperAdminDashboard = (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-sa-dashboard-dealership`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getDashboardDealershipStatewise = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-dashboard-dealership-statewise`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getTMReleaseDocument = (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-tm-release-document`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getAuctionPersonnelRelease = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-auction-personnel-release`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getBankNocAndTTODashboard = (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-bank-noc-tto-dashboard`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getStockDashboard = (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-stock-dashboard`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getRTOData = (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-milestone-status`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getRTOBeforeSaleData = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-task-rto-before-sale`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getRTOAfterSaleData = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-task-rto-after-sale`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getLoanAfterSaleData = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-task-loan-after-sale`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getTaskRTOExchangeBeforeSale = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-task-rto-exchange-before-sale`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getAccountPersonnelDashboard = (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-account-personnel-dashboard`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getAccountPersonnelUTRDashboard = (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-pending-invoices-utr`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  ReleasePendingStateWise = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/release-pending-state-wise`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  ReleasePendingFinancierWiseByState = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/release-pending-financier-wise-by-state`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  BankNocPendingStateWise = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/bankNoc-pending-state-wise`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  BankNocPendingFinancierWiseByState = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/bankNoc-pending-financier-wise-by-state`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetMaharashtaReports = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-maharashtra-reports`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetMadhyaPradeshReports = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-madhya-pradesh-reports`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetBLReports = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-bl-reports`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllSubIds = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-all-users`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAMdashboard = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-am-dashboard`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAssignedDealershipCount = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-assigned-dealerships-count`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAMWiseData = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-am-wise-data`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetTMWiseData = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-tm-wise-data`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetDealershipWiseData = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-dealership-wise-data`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetDealershipReleasePending = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-tm-dashboard-dealership-release`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  EditApprovalRequest = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/edit-approval-request`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetApprovalRequest = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-approval-request`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetApprovalRequestAdmin = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-approval-data-superadmin`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetApprovedData = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-approval-data`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  StateWiseUserAddedCount = async (formData) => {
    return axios({
      method: "post",
      url: `https://meratractor.com/dashboard/subscribed-user-count-date-range`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  getRapibidApprovalData = (formData) => {
    return axios({
      method: "post",
      url: `https://rapidbid.meratractor.com/auction/get-all-pending-approvals-erp`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  updateAuctionApproval = (formData) => {
    return axios({
      method: "post",
      url: `https://rapidbid.meratractor.com/auction/auction-approval`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
}

export default new ViewDashboard();
