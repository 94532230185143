import { useState, useEffect } from "react";
// import { errorToast } from "../react-toastfiy/toast";
import { errorToast } from "../react-toastfiy/toast";
import { formatDateDDMMYYYY } from "../helpers/functions/dateFormatter";

function SearchBox(props) {
  const {
    allData,
    setFilteredData,
    placeholder = "Search...",
    return_array = false,
    className = "",
  } = props;
  const [searchQuery, setSearchQuery] = useState("");

  // Debounce function
  function debounce(func, delay) {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }

  const handleSearchQueryChanged = (val, data) => {
    try {
      data = structuredClone(data);
      let searchInput = val.toLowerCase();

      if (searchInput.length === 0) {
        setFilteredData(allData);
        return;
      }

      const searchQueries = searchInput
        .split(" ")
        .filter((item) => item !== "");
      // const searchQueries = [searchInput]
      console.log({ searchQueries });
      let results = Object.entries(data).filter(([key, entry]) => {
        return searchQueries.some((item) => {
          item = item?.toString().toLowerCase();

          return (
            key?.toString()?.toLowerCase()?.startsWith(item) ||
            entry?.dealership?.toLowerCase()?.startsWith(item) ||
            entry?.dealer_name?.toLowerCase()?.startsWith(item) ||
            entry?.reference_no?.toLowerCase()?.startsWith(item) ||
            entry?.c_auction_id?.toLowerCase()?.startsWith(item) ||
            entry?.email_address?.toLowerCase()?.startsWith(item) ||
            entry?.dealer_code?.toLowerCase()?.startsWith(item) ||
            entry?.dealer_code?.toLowerCase()?.endsWith(item) ||
            entry?.mode_of_supply?.toLowerCase()?.startsWith(item) ||
            entry?.dealerCode?.toLowerCase()?.startsWith(item) ||
            entry?.tpid?.toLowerCase()?.startsWith(item) ||
            entry?.tp_id?.toLowerCase()?.startsWith(item) ||
            entry?.assign_to?.toLowerCase()?.startsWith(item) ||
            entry?.name_of_customer?.toLowerCase()?.startsWith(item) ||
            entry?.tm_name?.toLowerCase()?.startsWith(item) ||
            entry?.lot_number?.toLowerCase()?.startsWith(item) ||
            entry?.auction__c_auction_id?.toLowerCase()?.startsWith(item) ||
            entry?.loan_case_passed?.toLowerCase()?.startsWith(item) ||
            entry?.financier?.toString()?.toLowerCase()?.startsWith(item) ||
            entry?.reg_no?.toLowerCase()?.startsWith(item) ||
            entry?.reg_no?.toLowerCase()?.endsWith(item) ||
            entry?.chassis_no?.toLowerCase()?.startsWith(item) ||
            entry?.rto_agent_name?.toLowerCase()?.startsWith(item) ||
            entry?.rto_agent_phone?.toLowerCase()?.startsWith(item) ||
            entry?.phone?.toString()?.startsWith(item) ||
            entry?.make?.toLowerCase()?.startsWith(item) ||
            entry?.model?.toLowerCase()?.startsWith(item) ||
            entry?.loan_amount?.toString()?.toLowerCase()?.startsWith(item) ||
            entry?.location?.toLowerCase()?.startsWith(item) ||
            entry?.rto_code?.toLowerCase()?.startsWith(item) ||
            entry?.name?.toLowerCase()?.startsWith(item) ||
            entry?.user_phone?.toString()?.startsWith(item) ||
            entry?.make_name?.toLowerCase()?.startsWith(item) ||
            entry?.model_name?.toLowerCase()?.startsWith(item) ||
            entry?.user_name?.toLowerCase()?.startsWith(item) ||
            entry?.user_name?.toLowerCase()?.startsWith(item) ||
            entry?.code?.toLowerCase()?.startsWith(item) ||
            entry?.mt_price?.toLowerCase()?.startsWith(item) ||
            entry?.dealer_price?.toLowerCase()?.startsWith(item) ||
            entry?.sefaki_price?.toLowerCase()?.startsWith(item) ||
            entry?.state_name?.toLowerCase()?.startsWith(item) ||
            entry?.shorthand?.toLowerCase()?.startsWith(item) ||
            entry?.case_type?.toString()?.toLowerCase()?.startsWith(item) ||
            entry?.state?.toString()?.toLowerCase()?.startsWith(item) ||
            entry?.district?.toLowerCase()?.startsWith(item) ||
            entry?.tehsil?.toLowerCase()?.startsWith(item) ||
            entry?.type?.toLowerCase()?.startsWith(item) ||
            entry?.city?.toLowerCase()?.startsWith(item) ||
            entry?.address?.toLowerCase()?.startsWith(item) ||
            entry?.assigned_person?.toLowerCase()?.startsWith(item) ||
            entry?.implement_name?.toLowerCase()?.startsWith(item) ||
            entry?.implement?.toString()?.toLowerCase()?.startsWith(item) ||
            entry?.implement_dict?.implement
              ?.toString()
              ?.toLowerCase()
              .startsWith(item) ||
            entry?.implement_code?.toLowerCase()?.startsWith(item) ||
            entry?.spare_name?.toLowerCase()?.startsWith(item) ||
            entry?.spare?.toLowerCase()?.startsWith(item) ||
            entry?.spare_code?.toLowerCase()?.startsWith(item) ||
            entry?.total_price?.toLowerCase()?.startsWith(item) ||
            entry?.order_id?.toLowerCase()?.startsWith(item) ||
            entry?.order_status?.toLowerCase()?.startsWith(item) ||
            entry?.datetime?.toLowerCase()?.startsWith(item) ||
            entry?.serial_number?.toLowerCase()?.startsWith(item) ||
            entry?.item_track?.serial_number?.toLowerCase().startsWith(item) ||
            entry?.customer_dict?.name?.toLowerCase()?.startsWith(item) ||
            entry?.customer_dict?.phone?.toLowerCase()?.startsWith(item) ||
            entry?.customer?.name?.toLowerCase()?.startsWith(item) ||
            entry?.customer?.phone?.toLowerCase()?.startsWith(item) ||
            entry?.sefaki_dict?.name?.toLowerCase()?.startsWith(item) ||
            entry?.sefaki_dict?.phone?.toLowerCase()?.startsWith(item) ||
            entry?.sefaki?.name?.toLowerCase()?.startsWith(item) ||
            entry?.sefaki?.phone?.toLowerCase()?.startsWith(item) ||
            entry?.claimed_datetime?.toLowerCase().startsWith(item) ||
            entry?.c_auction_id?.toLowerCase().includes(item) ||
            entry?.auction_id?.toLowerCase().includes(item) ||
            entry?.reference_no?.toLowerCase().includes(item) ||
            entry?.toString()?.toLowerCase().startsWith(item) ||
            entry?.bid?.toString()?.toLowerCase()?.startsWith(item) ||
            entry?.make_model?.toString()?.toLowerCase()?.startsWith(item) ||
            entry?.yard?.toString()?.toLowerCase()?.startsWith(item) ||
            entry?.yard_name?.toString()?.toLowerCase()?.startsWith(item) ||
            entry?.segment?.toString()?.toLowerCase()?.startsWith(item) ||
            entry?.description?.toString()?.toLowerCase()?.startsWith(item) ||
            entry?.financier_name
              ?.toString()
              ?.toLowerCase()
              ?.startsWith(item) ||
            entry?.financier_shorthand
              ?.toString()
              ?.toLowerCase()
              ?.startsWith(item) ||
            entry?.reference_no?.toString()?.toLowerCase()?.startsWith(item) ||
            entry?.mfg_year?.toString()?.toLowerCase()?.startsWith(item) ||
            (entry?.from_date &&
              formatDateDDMMYYYY(entry?.from_date)
                ?.toLowerCase()
                ?.startsWith(item)) ||
            (entry?.to_date &&
              formatDateDDMMYYYY(entry?.to_date)
                ?.toLowerCase()
                ?.startsWith(item)) ||
            (entry?.approved_from_date &&
              formatDateDDMMYYYY(entry?.approved_from_date)
                ?.toLowerCase()
                ?.startsWith(item)) ||
            (entry?.approved_to_date &&
              formatDateDDMMYYYY(entry?.approved_to_date)
                ?.toLowerCase()
                ?.startsWith(item)) ||
            entry?.leave_reason?.toLowerCase()?.startsWith(item) ||
            entry?.rejected_reason?.toLowerCase()?.startsWith(item) ||
            entry?.request_approved_rejected_by
              ?.toString()
              ?.toLowerCase()
              ?.startsWith(item) ||
            entry?.user?.name?.toLowerCase()?.startsWith(item) ||
            entry?.phone?.toString()?.toLowerCase()?.startsWith(item) ||
            entry?.assets_count?.toString()?.toLowerCase()?.startsWith(item) ||
            entry?.rejected_reason?.phone
              ?.toString()
              ?.toLowerCase()
              ?.startsWith(item) ||
            entry?.plan_name?.toString()?.toLowerCase().includes(item) ||
            entry?.plan_id?.toString()?.toLowerCase().includes(item) ||
            entry?.plan_type?.toString()?.toLowerCase().includes(item) ||
            entry?.bidder_name?.toString()?.toLowerCase().includes(item) ||
            entry?.plan_category?.toString()?.toLowerCase().includes(item)
          );
        });
      });
      results = Object.fromEntries(results);
      console.log({ results });
      if (return_array) {
        results = Object.values(results);
      }
      setFilteredData(results);
    } catch (error) {
      console.error(error);
      errorToast(error?.message);
    }
  };

  // const newHandleSearchQueryChanged = (val, data) => {
  //   try {
  //     data = structuredClone(data);
  //     let searchInput = val.toLowerCase();
  //     if (searchInput.length === 0) {
  //       setFilteredData(allData);
  //       return;
  //     }

  //     const searchQueries = searchInput.split(" ").filter((item) => item !== "");

  //     // Search in the name key first
  //     let nameResults = Object.entries(data).filter(([key, entry]) => {
  //       return searchQueries.every((item) => {
  //         item = item?.toString().toLowerCase();
  //         return entry?.name?.toString()?.toLowerCase()?.startsWith(item);
  //       });
  //     });

  //     // Search in other keys excluding the name key
  //     let otherResults = Object.entries(data).filter(([key, entry]) => {
  //       return !nameResults.some(([nameKey, nameEntry]) => nameKey === key) &&
  //         searchQueries.some((item) => {
  //           item = item?.toString().toLowerCase();
  //           return (
  //             key?.toString()?.toLowerCase()?.startsWith(item) ||
  //             (entry && Object.keys(entry).some(subKey => {
  //               if (subKey === 'name' || subKey === 'reporting_personnel') return false; // Skip the name and reporting_personnel keys
  //               if (typeof entry[subKey] === 'string') {
  //                 return entry[subKey].toLowerCase().startsWith(item);
  //               } else if (entry[subKey] && typeof entry[subKey] === 'object' && !Array.isArray(entry[subKey])) {
  //                 return Object.keys(entry[subKey]).some(innerKey => {
  //                   return typeof entry[subKey][innerKey] === 'string' && entry[subKey][innerKey].toLowerCase().startsWith(item);
  //                 });
  //               }
  //               return false;
  //             }))
  //           );
  //         });
  //     });

  //     let results = [...nameResults, ...otherResults];
  //     results = Object.fromEntries(results);
  //     if (return_array) {
  //       results = Object.values(results);
  //     }
  //     setFilteredData(results);
  //   } catch (error) {
  //     console.error(error);
  //     errorToast(error?.message);
  //   }
  // };

  // const debouncedSearch = debounce(newHandleSearchQueryChanged, 300);

  useEffect(() => {
    handleSearchQueryChanged(searchQuery, allData);
    // newhandleSearchQueryChanged(searchQuery, allData);
    // debouncedSearch(searchQuery, allData);
  }, [searchQuery]);

  return (
    <input
      type="search"
      name="search-form"
      id="search-form"
      className={`form-control ${className}`}
      placeholder={placeholder}
      onChange={(e) => {
        setSearchQuery(e.target.value);
      }}
      value={searchQuery}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    />
  );
}

export default SearchBox;
