import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import Select from "react-select";
import Swal from "sweetalert2";
import SearchBox from "../../common/SearchBox";
import "../../css/common.css";
import { errorToast } from "../../react-toastfiy/toast";
import SubscriptionServices from "../../services/subscription/subscription.service";
import CustomTable from "./CustomTable";

const SubscriptionActiveSubscriptionList = () => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);

  const ActiveSubUserHeader = [
    {
      header: "Sr. No",
      dataKey: "no",
    },

    {
      header: "User Name",
      dataKey: "user_name",
    },
    {
      header: "User Phone",
      dataKey: "user_phone",
    },

    {
      header: "Org Name",
      dataKey: "org_name",
    },
    {
      header: "Plan Name",
      dataKey: "plan_name",
    },
    {
      header: "Platform Type",
      dataKey: "platform_type",
    },
    {
      header: "End Date",
      dataKey: "end_date",
    },
    {
      header: "Plan Type",
      dataKey: "plan_type",
    },
  ];

  const updated_Data = {
    user_name: "",
    plan_term_available: "",
    org_name: "",
    user_phone: "",
    plan_id: "",
    end_date: "",
    auto_renew: false,
    add_ons: [
      {
        plan_id: "",
        plan_name: "",
        plan_type: "",
        plan_term: {},
        add_on_state_permission: {
          multistate: {
            selected: false,
            total_add_on: "",
            states: [""],
          },
          user_state: true,
          all_state_access: false,
        },
      },
    ],
    plan_name: "",
    final_amount: "",
    plan_term: {},
    plan_type: "",
  };

  const newPlan = {
    status: 200,
    data: [
      {
        id: "MTRPsell30",
        name: "Sell Plan",
      },
      {
        id: "MTRPbuya31",
        name: "Buy and Sell Free Trial",
      },
      {
        id: "MTRPbuyp22",
        name: "Buy Plan",
      },
    ],
  };
  const State_permission = {
    status: 200,
    data: [
      {
        id: "MTRPguja24",
        name: "Gujarat State Permission",
        plan_term: {
          yearly: "5000",
          monthly: "50",
          quarterly: "500",
        },
        plan_type: "state-permission",
        state_permission: {
          multistate: {
            selected: false,
            total_add_on: "",
          },
          user_state: false,
          all_state_access: false,
        },
      },
      {
        id: "MTRPmaha25",
        name: "Maharashtra State Permission",
        plan_term: {
          yearly: "5000",
          monthly: "50",
          quarterly: "500",
        },
        plan_type: "state-permission",
        state_permission: {
          multistate: {
            selected: false,
            total_add_on: "",
          },
          user_state: false,
          all_state_access: false,
        },
      },
      {
        id: "MTRPmadh26",
        name: "Madhya Pradesh State Permission",
        plan_term: {
          yearly: "5000",
          monthly: "50",
          quarterly: "500",
        },
        plan_type: "state-permission",
        state_permission: {
          multistate: {
            selected: false,
            total_add_on: "",
          },
          user_state: false,
          all_state_access: false,
        },
      },
      {
        id: "MTRPbiha28",
        name: "Bihar State Permission",
        plan_term: {
          yearly: "5000",
          monthly: "50",
          quarterly: "500",
        },
        plan_type: "state-permission",
        state_permission: {
          multistate: {
            selected: false,
            total_add_on: "",
          },
          user_state: false,
          all_state_access: false,
        },
      },
      {
        id: "MTRPkarn27",
        name: "Karnataka State Permission",
        plan_term: {
          yearly: "5000",
          monthly: "50",
          quarterly: "500",
        },
        plan_type: "state-permission",
        state_permission: {
          multistate: {
            selected: false,
            total_add_on: "",
          },
          user_state: false,
          all_state_access: false,
        },
      },
      {
        id: "MTRPandr29",
        name: "Andra Pradesh State Permission",
        plan_term: {
          yearly: "5000",
          monthly: "50",
          quarterly: "500",
        },
        plan_type: "state-permission",
        state_permission: {
          multistate: {
            selected: false,
            total_add_on: "",
          },
          user_state: false,
          all_state_access: false,
        },
      },
    ],
  };

  const Add_on_Data = {
    status: 200,
    data: [
      {
        id: "MTRPaddo32",
        name: "Add on plan 2",
        plan_term: {
          yearly: "1000",
          monthly: "100",
          quarterly: "300",
        },
        plan_type: "add-on",
        add_on_state_permission: {
          multistate: {
            selected: true,
            total_add_on: "2",
          },
          user_state: false,
          all_state_access: false,
        },
      },
      {
        id: "MTRPaddo23",
        name: "Add On for Limited States",
        plan_term: {
          yearly: "10000",
          monthly: "100",
          quarterly: "1000",
        },
        plan_type: "add-on",
        add_on_state_permission: {
          multistate: {
            selected: true,
            total_add_on: "3",
          },
          user_state: false,
          all_state_access: false,
        },
      },
    ],
  };

  const option_new_plan = newPlan.data.map((val) => {
    return {
      label: `${val.name}  ${val.id}`,
      value: val,
    };
  });
  const option_addon_plan = Add_on_Data.data.map((val) => {
    return {
      label: `${val.name}  ${val.id}`,
      value: val,
    };
  });
  const option_State_permission = State_permission.data.map((val) => {
    return {
      label: `${val.name}  ${val.id}`,
      value: val,
    };
  });

  const [activeUsers, setActiveUsers] = useState([]);
  const [filterUsersData, setFilterUsersData] = useState([]);
  const [updateData, setUpdatedData] = useState(updated_Data);
  const [Loading, setLoading] = useState(false);
  const [updatedFlag, setUpdatedFlag] = useState(false);
  const [allNewPlan, setAllNewPlan] = useState([]);
  const [allAddOnPlan, setAllAddOnPlan] = useState([]);
  const [allStatePermission, setAllStatePermission] = useState(
    option_State_permission
  );
  const [accordionOpenStates, setAccordionOpenStates] = useState(
    Array(filterUsersData?.length).fill(false)
  );

  let plan_id = updateData.plan_id;

  useEffect(() => {
    try {
      setLoading(true);
      let form_data = new FormData();
      form_data.append("data_type", "active");
      SubscriptionServices.GetAllRapibidUsers(form_data).then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          const user_data = res?.data?.map((data, index) => ({
            ...data,
            no: index + 1,
          }));
          setActiveUsers(user_data);
          setFilterUsersData(user_data);
          SubscriptionServices.GetAllNewSubscriptionPlan().then((res) => {
            if (res?.status === 200) {
              setLoading(false);
              let newPlan = res.data.map((val) => {
                return {
                  label: `${val.name}  ${val.id}`,
                  value: val,
                };
              });
              setAllNewPlan(newPlan);
            }
          });
        } else {
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (plan_id !== "") {
      let form_data = new FormData();
      form_data.append("plan_id", plan_id);
      SubscriptionServices.GetAllAddonSubscriptionPlan(form_data).then(
        (res) => {
          if (res?.status === 200) {
            let all_AddOn_Plan = res.data.map((val) => {
              return {
                label: `${val.name}  ${val.id}`,
                value: val,
              };
            });

            setAllAddOnPlan(all_AddOn_Plan);
          }
        }
      );
      SubscriptionServices.GetAllStatePermissionsSubscriptionPlan().then(
        (res) => {
          if (res?.status === 200) {
            let all_state_permission = res.data.map((val) => {
              return {
                label: `${val.name}  ${val.id}`,
                value: val,
              };
            });
            setAllStatePermission(all_state_permission);
          }
        }
      );
    }
  }, [plan_id && accordionOpenStates]);

  const handleChangeNewPlan = (e, index, value) => {
    const newPlanId = e.value.id;
    setActiveUsers((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        plan_term_selected: {},
        plan_id: newPlanId,
        plan_name: e.value.name,
        plan_term_available: e?.value?.plan_term,
        add_ons: [],
        state_permissions: [],
      };
      return newState;
    });
    setFilterUsersData((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        plan_term_selected: {},
        plan_id: newPlanId,
        plan_name: e.value.name,
        plan_term_available: e?.value?.plan_term,
        add_ons: [],
        state_permissions: [],
      };
      return newState;
    });
    setUpdatedFlag(false);

    setUpdatedData((prevState) => {
      const newState = { ...prevState };
      newState.plan_name = e.value.name;
      newState.plan_term_available = e.value.plan_term;
      newState.plan_id = newPlanId;
      newState.plan_term = {};
      newState.plan_type = value?.plan_type;
      newState.final_amount = "";
      newState.user_name = value.user_name;
      newState.user_phone = value.user_phone;
      newState.org_name = value.org_name;
      newState.end_date = value.end_date;
      newState.add_ons = [];
      return newState;
    });

    let form_data = new FormData();
    form_data.append("plan_id", e.value.id);
    SubscriptionServices.GetAllAddonSubscriptionPlan(form_data).then((res) => {
      if (res?.status === 200) {
        let all_AddOn_Plan = res?.data.map((val) => {
          return {
            label: `${val.name}  ${val.id}`,
            value: val,
          };
        });
        setAllAddOnPlan(all_AddOn_Plan);
      }
    });
  };

  const handleChangeAddonPlan = (e, index, value) => {
    const newPlanId = e.value.id;
    const plan_term = Object.keys(value.plan_term_selected).map(
      (key, index) => {
        const selectedValue = key;
        return selectedValue;
      }
    );

    let state_list = value.state_permissions;

    const state_permission_values = state_list.map((stateId) => {
      return allStatePermission.find((option) => option?.value?.id === stateId);
    });

    const setPlan_term = { [plan_term[0]]: e.value.plan_term[plan_term[0]] };

    let slugResults = [];

    state_list?.forEach((statePermission) => {
      const foundValue = state_permission_values?.find(
        (item) => item.value.id === statePermission
      );
      const slug = {
        plan_id: foundValue?.value?.id || "",
        plan_name: foundValue?.value?.name || "",

        plan_term: {
          [plan_term[0]]:
            slugResults.length <
            e?.value?.add_on_state_permission.multistate.total_add_on
              ? 0
              : foundValue?.value?.plan_term[plan_term[0]],
        },
        plan_type: foundValue?.value?.plan_type,
      };

      slugResults.push(slug);
    });

    setActiveUsers((prevState) => {
      return prevState.map((user, i) => {
        if (i === index) {
          return {
            ...user,
            add_ons: [newPlanId],
          };
        }
        return user;
      });
    });
    setFilterUsersData((prevState) => {
      return prevState.map((user, i) => {
        if (i === index) {
          return {
            ...user,
            add_ons: [newPlanId],
          };
        }
        return user;
      });
    });
    setUpdatedFlag(true);
    setUpdatedData((prevState) => {
      const newState = { ...prevState };
      newState.plan_name = e.value.name;
      newState.plan_term_available = value.plan_term_available;
      newState.plan_term = value.plan_term_selected;
      newState.plan_id = value.plan_id;
      newState.plan_type = value?.plan_type;
      newState.user_name = value.user_name;
      newState.user_phone = value.user_phone;
      newState.org_name = value.org_name;
      newState.end_date = value.end_date;
      newState.add_ons = [
        {
          plan_id: e.value.id,
          plan_name: e.value.name,
          plan_type: e.value.plan_type,
          plan_term: setPlan_term,
          add_on_state_permission: e.value.add_on_state_permission,
        },

        ...slugResults,
      ];
      return newState;
    });
  };

  const handleChangeStatePermissionPlan = (e, index, value) => {
    const newPlanIdArray = e.map((item) => item.value.id);
    let state_list = e.map((val) => val?.value?.id);
    const plan_term = Object.keys(value.plan_term_selected).map(
      (key, index) => {
        const selectedValue = key;

        return selectedValue;
      }
    );
    const foundValues = allStatePermission.filter((item) =>
      value?.state_permissions?.includes(item.value.id)
    );

    const state_permission_list = e.map((val) => val.value);
    const add_ons_value = allAddOnPlan.find((option) =>
      value.add_ons.includes(option?.value?.id)
    );

    let state = [];
    const slugResults = [];
    if (
      add_ons_value &&
      add_ons_value.value.add_on_state_permission.multistate.total_add_on
    ) {
      state_list?.forEach((statePermission) => {
        const foundValue = state_permission_list?.find(
          (item) => item?.id === statePermission
        );
        const slug = {
          plan_id: foundValue?.id || "",
          plan_name: foundValue?.name || "",

          plan_term: {
            [plan_term[0]]:
              slugResults.length <
              add_ons_value.value.add_on_state_permission.multistate
                .total_add_on
                ? 0
                : foundValue?.plan_term[plan_term[0]],
          },
          plan_type: foundValue?.plan_type,
        };

        slugResults.push(slug);
      });

      const setPlan_term = {
        [plan_term[0]]: add_ons_value?.value?.plan_term[plan_term[0]],
      };
      setActiveUsers((prevState) => {
        return prevState.map((user, i) => {
          if (i === index) {
            const existingPlanIds = user.state_permissions.map((item) => item);

            const newPlanIdsToAdd = newPlanIdArray.filter(
              (newPlanId) => !existingPlanIds.includes(newPlanId)
            );

            return {
              ...user,
              state_permissions: [
                ...user.state_permissions,
                ...newPlanIdsToAdd,
              ],
            };
          }
          return user;
        });
      });
      setFilterUsersData((prevState) => {
        return prevState.map((user, i) => {
          if (i === index) {
            const existingPlanIds = user.state_permissions.map((item) => item);

            const newPlanIdsToAdd = newPlanIdArray.filter(
              (newPlanId) => !existingPlanIds.includes(newPlanId)
            );

            return {
              ...user,
              state_permissions: [
                ...user.state_permissions,
                ...newPlanIdsToAdd,
              ],
            };
          }
          return user;
        });
      });
      setUpdatedFlag(true);
      setUpdatedData((prevState) => {
        const newState = { ...prevState };
        newState.plan_name = value.plan_name;
        newState.plan_term_available = value.plan_term_available;
        newState.plan_term = value.plan_term_selected;
        newState.plan_id = value.plan_id;
        newState.plan_type = value?.plan_type;
        newState.user_name = value.user_name;
        newState.user_phone = value.user_phone;
        newState.org_name = value.org_name;
        newState.end_date = value.end_date;
        newState.add_ons = [
          {
            plan_id: add_ons_value?.value?.id,
            plan_name: add_ons_value?.value?.name,
            plan_type: add_ons_value?.value?.plan_type,
            plan_term: setPlan_term,
            states: state,
            add_on_state_permission:
              add_ons_value?.value?.add_on_state_permission,
          },
          ...slugResults,
        ];

        return newState;
      });
    } else {
      state_list?.forEach((statePermission) => {
        const foundValue = state_permission_list?.find(
          (item) => item?.id === statePermission
        );
        const slug = {
          plan_id: foundValue?.id || "",
          plan_name: foundValue?.name || "",

          plan_term: {
            [plan_term[0]]: foundValue?.plan_term[plan_term[0]],
          },
          plan_type: foundValue?.plan_type,
        };

        const state_id = [foundValue?.plan_state_id];

        slugResults.push(slug);
        state.push(...state_id);
      });

      setActiveUsers((prevState) => {
        return prevState.map((user, i) => {
          if (i === index) {
            const existingPlanIds = user.state_permissions.map((item) => item);

            const newPlanIdsToAdd = newPlanIdArray.filter(
              (newPlanId) => !existingPlanIds.includes(newPlanId)
            );

            return {
              ...user,
              state_permissions: [
                ...user.state_permissions,
                ...newPlanIdsToAdd,
              ],
            };
          }
          return user;
        });
      });
      setFilterUsersData((prevState) => {
        return prevState.map((user, i) => {
          if (i === index) {
            const existingPlanIds = user.state_permissions.map((item) => item);

            const newPlanIdsToAdd = newPlanIdArray.filter(
              (newPlanId) => !existingPlanIds.includes(newPlanId)
            );

            return {
              ...user,
              state_permissions: [
                ...user.state_permissions,
                ...newPlanIdsToAdd,
              ],
            };
          }
          return user;
        });
      });
      setUpdatedFlag(true);
      setUpdatedData((prevState) => {
        const newState = { ...prevState };
        newState.plan_name = value.plan_name;
        newState.plan_term_available = value.plan_term_available;
        newState.plan_term = value.plan_term_selected;
        newState.plan_id = value.plan_id;
        newState.plan_type = value?.plan_type;
        newState.user_name = value.user_name;
        newState.user_phone = value.user_phone;
        newState.org_name = value.org_name;
        newState.end_date = value.end_date;
        newState.add_ons = [...slugResults];

        return newState;
      });
    }
  };

  const handleAccordionButtonClick = (e, index, value) => {
    const planTerm = e.target.getAttribute("plan_term");
    const ariaExpanded = e.target.getAttribute("aria-expanded");
    let resultArray;
    if (Array.isArray(filterUsersData)) {
      resultArray = Array(filterUsersData.length).fill(false);
    } else if (typeof filterUsersData === "object") {
      resultArray = Array(Object.keys(filterUsersData).length).fill(false);
    }
    if (index >= 0 && index < resultArray.length) {
      resultArray = resultArray.map((indexvalue, i) =>
        index === i ? !indexvalue : false
      );

      setAccordionOpenStates(resultArray);
      if (accordionOpenStates[index] === true) {
        setAccordionOpenStates((prevState) => {
          const updatedStates = [...prevState];
          updatedStates[index] = !updatedStates[index];
          return updatedStates;
        });
      }
    } else {
      setAccordionOpenStates((prevState) => {
        const updatedStates = [...prevState];
        updatedStates[index] = !updatedStates[index];
        return updatedStates;
      });
    }
    setUpdatedFlag(false);
    setUpdatedData((prevState) => ({
      ...prevState,
      plan_term: value.plan_term_selected,
      plan_name: value?.plan_name,
      plan_term_available: value?.plan_term_available,
      plan_id: value?.plan_id,
      plan_type: value?.plan_type,
      user_name: value?.user_name,
      user_phone: value?.user_phone,
      org_name: value?.org_name,
      end_date: value?.end_date,
      add_ons: [],
    }));
  };

  const handleSave = (e, index) => {
    let order_id = filterUsersData[index].order_id;

    let form_data = new FormData();
    form_data.append("assigned_by", user.phone);
    form_data.append("end_date", updateData.end_date);
    form_data.append("final_amount", updateData.final_amount);
    form_data.append("auto_renew", false);
    form_data.append("org_name", updateData.org_name);
    form_data.append("plan_id", updateData.plan_id);
    form_data.append("plan_name", updateData.plan_name);
    form_data.append("plan_term", JSON.stringify(updateData.plan_term));
    form_data.append("order_id", order_id);
    form_data.append(
      "plan_term_available",
      JSON.stringify(updateData.plan_term_available)
    );
    form_data.append("plan_type", updateData.plan_type);
    form_data.append("user_name", updateData.user_name);
    form_data.append("user_phone", updateData.user_phone);
    form_data.append("add_ons", JSON.stringify(updateData.add_ons));

    Swal.fire({
      title: "Are you sure?",
      html: "You want to Save this subscription plan",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes,Save it!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await SubscriptionServices.UpdateUserList(form_data);

          if (response.status === 200) {
            Swal.fire("Save", `Your data has been Save`, "success").then(
              (result) => {
                result &&
                  window.location.replace(
                    "/subscription/manage-users-analysis"
                  );
              }
            );
          }
          response.status !== 200 && errorToast(response.msg);
        } catch (error) {
          console.log(error);
          errorToast(error.message);
        }
      },
    });
  };
  const handleAccordionBodyClick = (e) => {
    e.stopPropagation();
  };

  const handleCheckedRadio = (e, index, value) => {
    const { checked } = e?.target;

    const planTerm = e.target.getAttribute("plan_term");

    const add_ons_value = allAddOnPlan.find((option) =>
      value?.add_ons.includes(option?.value?.id)
    );

    const setPlan_term = {
      [planTerm]: add_ons_value?.value?.plan_term[planTerm],
    };

    const foundValues = allStatePermission.filter((item) =>
      value?.state_permissions?.includes(item.value.id)
    );

    const slugResults = [];
    let man_total = parseInt(e.target.value);

    if (value?.state_permissions !== 0 && add_ons_value && foundValues) {
      value?.state_permissions?.forEach((statePermission) => {
        const foundValue = foundValues?.find(
          (item) => item?.value?.id === statePermission
        );

        const slug = {
          plan_id: foundValue?.value?.id || "",
          plan_name: foundValue?.value.name || "",
          plan_term: {
            [planTerm]:
              slugResults.length <
              add_ons_value.value.add_on_state_permission.multistate
                .total_add_on
                ? 0
                : foundValue.value.plan_term[planTerm],
          },
          plan_type: foundValue.value.plan_type,
        };

        slugResults.push(slug);
      });
      setUpdatedFlag(true);
      setUpdatedData((prevState) => ({
        ...prevState,
        plan_term: {
          [planTerm]: e?.target?.value,
        },
        plan_name: value?.plan_name,
        final_amount: "",
        plan_term_available: value?.plan_term_available,
        plan_id: value?.plan_id,
        plan_type: value?.plan_type,
        user_name: value?.user_name,
        user_phone: value?.user_phone,
        org_name: value?.org_name,
        end_date: value?.end_date,
        add_ons: [
          {
            plan_id: add_ons_value?.value?.id,
            plan_name: add_ons_value?.value?.name,
            plan_type: add_ons_value?.value?.plan_type,
            plan_term: setPlan_term,

            add_on_state_permission:
              add_ons_value?.value?.add_on_state_permission,
          },
          ...slugResults,
        ],
      }));

      setActiveUsers((prevState) => {
        const updatedDataCopy = [...prevState];
        updatedDataCopy[index].plan_term_selected = {
          [planTerm]: e?.target?.value,
        };
        return updatedDataCopy;
      });
      setFilterUsersData((prevState) => {
        const updatedDataCopy = [...prevState];
        updatedDataCopy[index].plan_term_selected = {
          [planTerm]: e?.target?.value,
        };
        return updatedDataCopy;
      });
    } else {
      value?.state_permissions?.forEach((statePermission) => {
        const foundValue = foundValues?.find(
          (item) => item?.value?.id === statePermission
        );
        const slug = {
          plan_id: foundValue?.value?.id || "",
          plan_name: foundValue?.value.name || "",
          plan_term: {
            [planTerm]: foundValue.value.plan_term[planTerm],
          },
          plan_type: foundValue.value.plan_type,
        };

        slugResults.push(slug);
      });

      setUpdatedFlag(true);
      setUpdatedData((prevState) => ({
        ...prevState,
        plan_term: {
          [planTerm]: e?.target?.value,
        },
        plan_name: value?.plan_name,
        final_amount: man_total,
        plan_term_available: value?.plan_term_available,
        plan_id: value?.plan_id,
        plan_type: value?.plan_type,
        user_name: value?.user_name,
        user_phone: value?.user_phone,
        org_name: value?.org_name,
        end_date: value?.end_date,
        add_ons: [...slugResults],
      }));
      setActiveUsers((prevState) => {
        const updatedDataCopy = [...prevState];
        updatedDataCopy[index].plan_term_selected = {
          [planTerm]: e?.target?.value,
        };
        updatedDataCopy[index].add_ons = [];
        return updatedDataCopy;
      });
      setFilterUsersData((prevState) => {
        const updatedDataCopy = [...prevState];
        updatedDataCopy[index].plan_term_selected = {
          [planTerm]: e?.target?.value,
        };
        updatedDataCopy[index].add_ons = [];
        return updatedDataCopy;
      });
    }
  };

  const handleRemoveAddOnItem = (item, index) => {
    let planTerm = filterUsersData[index].plan_term_selected;
    let plan_Term = Object.keys(planTerm).map((key, index) => {
      const selectedValue = key;
      return selectedValue;
    });

    const currentActiveUser = filterUsersData[index];
    const updatedValue = currentActiveUser.add_ons.filter(
      (value) => value !== item
    );
    const slugResults = [];
    if (updatedValue == 0) {
      const foundValues = allStatePermission.filter((item) =>
        filterUsersData[index]?.state_permissions?.includes(item.value.id)
      );

      filterUsersData[index]?.state_permissions?.forEach((statePermission) => {
        const foundValue = foundValues?.find(
          (item) => item?.value?.id === statePermission
        );
        const slug = {
          plan_id: foundValue?.value?.id || "",
          plan_name: foundValue?.value.name || "",
          plan_term: {
            [plan_Term]: foundValue.value.plan_term[plan_Term],
          },
          plan_type: foundValue.value.plan_type,
        };

        slugResults.push(slug);
      });
    }

    setActiveUsers((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...prevState[index],
        add_ons: [...updatedValue],
      };
      return newState;
    });
    setFilterUsersData((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...prevState[index],
        add_ons: [...updatedValue],
      };
      return newState;
    });
    setUpdatedFlag(true);

    setUpdatedData((value) => ({
      ...value,
      add_ons: [...slugResults],
    }));
  };

  const handleRemoveStatePermission = (item, index, value) => {
    const currentActiveUser = filterUsersData[index];
    const plan_term = Object.keys(value.plan_term_selected).map(
      (key, index) => {
        const selectedValue = key;

        return selectedValue;
      }
    );

    const updatedValue = currentActiveUser.state_permissions.filter(
      (value) => value !== item
    );
    const add_ons_value = allAddOnPlan.find((option) =>
      value.add_ons.includes(option?.value?.id)
    );

    let slugResults = [];
    if (
      add_ons_value &&
      add_ons_value.value.add_on_state_permission.multistate.total_add_on
    ) {
      const setPlan_term = {
        [plan_term[0]]: add_ons_value?.value?.plan_term[plan_term[0]],
      };

      updatedValue?.forEach((statePermission) => {
        const foundValue = allStatePermission?.find(
          (item) => item.value.id === statePermission
        );
        const slug = {
          plan_id: foundValue?.value?.id || "",
          plan_name: foundValue?.value?.name || "",

          plan_term: {
            [plan_term[0]]:
              slugResults.length <
              add_ons_value.value.add_on_state_permission.multistate
                .total_add_on
                ? 0
                : foundValue?.value?.plan_term[plan_term[0]],
          },
          plan_type: foundValue?.value?.plan_type,
        };

        slugResults.push(slug);
      });

      setActiveUsers((prevState) => {
        const newState = [...prevState];
        newState[index] = {
          ...prevState[index],
          state_permissions: [...updatedValue],
        };
        return newState;
      });
      setFilterUsersData((prevState) => {
        const newState = [...prevState];
        newState[index] = {
          ...prevState[index],
          state_permissions: [...updatedValue],
        };
        return newState;
      });
      setUpdatedFlag(true);
      setUpdatedData((value) => ({
        ...value,
        add_ons: [
          {
            plan_id: add_ons_value?.value?.id,
            plan_name: add_ons_value?.value?.name,
            plan_type: add_ons_value?.value?.plan_type,
            plan_term: setPlan_term,
            // states: state,
            add_on_state_permission:
              add_ons_value?.value?.add_on_state_permission,
          },
          ...slugResults,
        ],
      }));
    } else {
      updatedValue?.forEach((statePermission) => {
        const foundValue = allStatePermission?.find(
          (item) => item.value.id === statePermission
        );
        const slug = {
          plan_id: foundValue?.value?.id || "",
          plan_name: foundValue?.value?.name || "",

          plan_term: {
            [plan_term[0]]: foundValue?.value?.plan_term[plan_term[0]],
          },
          plan_type: foundValue?.value?.plan_type,
        };

        slugResults.push(slug);
      });

      setActiveUsers((prevState) => {
        const newState = [...prevState];
        newState[index] = {
          ...prevState[index],
          state_permissions: [...updatedValue],
        };
        return newState;
      });
      setFilterUsersData((prevState) => {
        const newState = [...prevState];
        newState[index] = {
          ...prevState[index],
          state_permissions: [...updatedValue],
        };
        return newState;
      });
      setUpdatedFlag(true);
      setUpdatedData((value) => ({
        ...value,
        add_ons: [...slugResults],
      }));
    }
  };

  let Total = Object.values(updateData.plan_term).join("");
  const result = updateData.add_ons.map((val) =>
    Object.values(val.plan_term).join("")
  );
  const totaladdOns = result.reduce((acc, currentValue) => {
    return acc + parseInt(currentValue);
  }, 0);
  let grand_total = parseInt(Total) + parseInt(totaladdOns);

  useEffect(() => {
    if (grand_total) {
      setUpdatedData((prevData) => {
        return {
          ...prevData,
          final_amount: parseInt(Total) + parseInt(totaladdOns),
        };
      });
    }

    // }
  }, [grand_total]);

  const handleChangeEndDate = (e, index, value) => {
    const { name } = e.target;
    const newValue = e.target.value.replace("T", " ");

    setUpdatedData((prevState) => {
      return {
        ...prevState,
        [name]: newValue,
      };
    });

    setActiveUsers((prevState) => {
      const updatedUsers = [...prevState];
      updatedUsers[index][name] = newValue;
      return updatedUsers;
    });
    setFilterUsersData((prevState) => {
      const updatedUsers = [...prevState];
      updatedUsers[index][name] = newValue;
      return updatedUsers;
    });
  };

  console.log({ filterUsersData });

  return (
    <>
      <div className="container mt-3">
        <div className="col-md-12 col-12 col-lg-12 mb-2 ">
          <div className="d-flex flex-wrap justify-content-between align-items-end">
            <div className="col-lg-3 col-12 col-md-4 col-sm-12">
              <h4>Active User Subscription List</h4>
            </div>
            <div className="col-lg-3 col-12 col-md-4 col-sm-12 ms-auto">
              <SearchBox
                allData={activeUsers}
                setFilteredData={setFilterUsersData}
              />
            </div>
          </div>
        </div>
        <CustomTable
          loading={Loading}
          columns={ActiveSubUserHeader}
          data={filterUsersData}
          // handleDelete={handleDelete}
          action={"active"}
        />
      </div>
    </>
  );
};

export default SubscriptionActiveSubscriptionList;
