import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import { errorToast } from "../../../../react-toastfiy/toast";
import auctionService from "../../../../services/auction/auction.service";

const SingleUserTransaction = () => {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);
  const history = useHistory();
  const [isSmallScreenBreadCrum, setIsSmallScreenBreadCrum] = useState(
    window.innerWidth < 380
  );
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 430);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const singleuploadbreadcrumb = {
    height: isSmallScreenBreadCrum ? "60px" : isSmallScreen ? "60px" : "30px",
    borderRadius: "0",
  };

  const singleuploadbreadcrumbMargin = {
    marginTop: isSmallScreen ? "-14px" : "-12px",
    marginBottom: isSmallScreen ? "14px" : "",
  };

  console.log(window.innerWidth, { isSmallScreen });
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 430);
    setIsSmallScreenBreadCrum(window.innerWidth < 380);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let initialValue = {
    phone: "",
    name: "",
    amount: "",
    description: "",
    transaction_type: "",
  };
  const options = [
    {
      label: "C(Credit)",
      value: "C",
    },
    {
      label: "D(Debit)",
      value: "D",
    },
  ];

  const [data, setData] = useState(initialValue);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [namefield, setNameField] = useState(false);
  const [phoneField, setPhoneField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const source = axios.CancelToken.source();

  useEffect(() => {
    try {
      console.log(data?.phone.length);
      if (data?.phone?.length >= 4 && loading === true) {
        const form_data = new FormData();
        form_data.append("phone", data?.phone);
        auctionService.GetUserList(form_data, source).then((res) => {
          setLoading(false);
          if (Object.entries(res)?.length > 0) {
            const suggestions_Data = Object.values(res);
            setSuggestions(suggestions_Data);
          }
        });
      } else if (data?.name?.length >= 3 && loading === true) {
        const form_data = new FormData();
        form_data.append("name", data?.name);
        auctionService.GetUserList(form_data, source).then((res) => {
          setLoading(false);
          console.log({ res });
          if (Object.entries(res)?.length > 0) {
            const suggestions_Data = Object.values(res);
            setSuggestions(suggestions_Data);
          }
        });
      }
    } catch (error) {
      errorToast(error.msg);
    }
  }, [data?.name, data?.phone, loading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value) {
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));
      name === "phone" ? setPhoneField(true) : setNameField(true);
    } else {
      setData((prev) => ({
        ...prev,
        [name]: value,
        ["name"]: "",
      }));
      setSuggestions([]);
      setPhoneField(false);
      setNameField(false);
    }

    setLoading(true);
  };

  const handleSuggestionSelect = (suggestion) => {
    setData((prev) => ({
      ...prev,
      ["name"]: suggestion.name,
      ["phone"]: suggestion.phone,
    }));
    setSuggestions([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form_data = new FormData();
    form_data.append("phone", data?.phone);
    form_data.append("name", data?.name);
    form_data.append("amount", data?.amount);
    form_data.append("description", data?.description);
    form_data.append("transaction_type", data?.transaction_type);

    Swal.fire({
      title: "Are you sure?",
      html: `<p>Do you wan't to ${
        data?.transaction_type === "C" ? "Credit" : "Debit"
      } ${data?.amount}RS. to ledger ${data?.phone} (${data?.name})?</p>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ok!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await auctionService.CreateSingleTransaction(
            form_data, source
          );
          console.log({ response });
          if (response?.status === 200) {
            console.log({ response });
            setData([]);
            errorToast(
              response.success === true && "ledger update successfully"
            );
          } else {
            console.log({ response });
          }
        } catch (error) {
          console.log(error);
          errorToast(error?.message);
        }
      },
    });
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-3 mb-2">
          <span className="d-flex justify-content-between col-1">
            <i
              className="bi bi-arrow-left-circle "
              onClick={() =>
                history.push("/meratractor/wallet/single-user-txn")
              }
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </span>

          <div className="col-10 mt-2 float-start ms-2">
            <h4 className="">Single Transaction</h4>
          </div>
        </div>
      </div>
      <div className="container d-flex d-flex-wrap justify-content-between ">
        <div
          className={
            isSmallScreen
              ? "col-md-5  col-12 bg-body p-3 mb-2  rounded"
              : "col-md-6 col-lg-5  col-12   bg-body p-3 mb-2  rounded"
          }
          style={{
            marginTop: "2rem",
          }}
        >
          <hr className="soften mt-2" />
          <form className="ms-auto" onSubmit={handleSubmit}>
            <div className="row mt-3">
              <div className="col-md-12 mb-3 form-group">
                <label for="phone">Phone</label>
                <input
                  className="form-control"
                  placeholder="Phone"
                  id="phone"
                  type="number"
                  name="phone"
                  value={data?.phone}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  required
                  isDisabled={loading}
                />
                {phoneField && (
                  <div className="suggestion-box">
                    {suggestions && suggestions.length > 0 && (
                      <>
                        {suggestions.map((suggestion, index) => (
                          <div
                            key={"suggestion_" + index}
                            className="suggestion-item"
                            onClick={() => handleSuggestionSelect(suggestion)}
                            style={{ cursor: "pointer" }}
                          >
                            {suggestion.name} - {suggestion.phone}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12 mb-3 form-group">
                <label htmlFor="name">Name</label>
                <input
                  className="form-control"
                  placeholder="Name"
                  id="name"
                  type="text"
                  name="name"
                  value={data?.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  required
                  isDisabled={loading}
                />
                {namefield && (
                  <div className="suggestion-box">
                    {suggestions && suggestions.length > 0 && (
                      <>
                        {suggestions.map((suggestion, index) => (
                          <div
                            key={"suggestion_" + index}
                            className="suggestion-item"
                            onClick={() => handleSuggestionSelect(suggestion)}
                            style={{ cursor: "pointer" }}
                          >
                            {suggestion.name} - {suggestion.phone}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12 mb-3 form-group">
                <label htmlFor="amount">Amount</label>
                <input
                  className="form-control"
                  placeholder="Amount"
                  id="amount"
                  type="number"
                  name="amount"
                  value={data.first_name}
                  required
                  onChange={(e) => {
                    setData((prev) => ({
                      ...prev,
                      ["amount"]: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12 mb-3 form-group">
                <label htmlFor="description">Description</label>
                <textarea
                  className="form-control"
                  placeholder="Description"
                  id="description"
                  type="text"
                  name="description"
                  value={data.description}
                  required
                  onChange={(e) => {
                    setData((prev) => ({
                      ...prev,
                      ["description"]: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12 mb-3 form-group">
                <label htmlFor="transaction_type">Transaction Type</label>
                <Select
                  id="transaction_type"
                  options={options}
                  value={options.find(
                    (option) => option.value === data.transaction_type
                  )}
                  onChange={(e) => {
                    setData((prev) => ({
                      ...prev,
                      ["transaction_type"]: e.value,
                    }));
                  }}
                  placeholder="Transaction Type"
                  required
                />
              </div>
            </div>
            <div className="d-flex  justify-content-center  mt-4">
              {buttonLoading ? (
                <button
                  className="btn btn-primary mt-2"
                  type="button"
                  disabled
                  id="loadingBtnTp"
                >
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button className="btn btn-primary  col-4" type="submit">
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SingleUserTransaction;
